<template>
    <div class="home-page">

<div v-if="isLiveStreamActive" class="live-stream-alert" @click="goToLiveStream">
  <p>¡Tenemos una clase en vivo en este momento! ¡No te la pierdas!</p>
</div>


    <!-- Mostrar mensaje si el teléfono no está verificado -->
    <div v-if="!isPhoneVerified" class="alert-message">
      <p>
        Las señales de alerta se envían por SMS. Verifica tu número de teléfono para comenzar a recibir las alertas del sistema.
        <a href="/verify" class="verify-link">Verificar ahora</a>
      </p>
    </div>



<!-- Mostrar el mensaje si la suscripción está vencida o no es premium -->
<div v-if="!isPremium || isSubscriptionExpired" class="subscription-expired-message">
  <h2 class="expired-title">¡Tu acceso premium ha expirado!</h2>
  <p>Tu acceso finalizó el: <strong>{{ formattedExpirationDate }}</strong>.</p>
  <p>Para seguir disfrutando de nuestros beneficios exclusivos, renueva tu acceso premium hoy:</p>

  <!-- Opciones de suscripción -->
  <div class="subscription-options">
    <div class="option" @click="createSubscription(30)">
      <h3 class="option-title">30 días <span class="popular-tag">Más Popular</span></h3>
      <p class="option-price">$29.00 USD</p>
    </div>

    <div class="option" @click="createSubscription(365)">
      <h3 class="option-title">1 Año</h3>
      <p class="option-price">$249.00 USD <span class="savings">Ahorras 99 USD</span></p>
    </div>
  </div>

  <p class="contact-message">
    ¿Tienes preguntas? Contacta con el administrador para más información.
  </p>
</div>

<!-- Mostrar la fecha de expiración si la suscripción está activa -->
<div v-else-if="premiumExpirationDate && !isSubscriptionExpired" class="premium-expiration">
  <p>Tu acceso premium finalizará el: <strong>{{ formattedExpirationDate }}</strong>.</p>
</div>




<div class="account-menu-container">
  <button @click="toggleMenu" class="account-menu-button">Opciones de Cuenta</button>
  <div v-if="menuOpen" class="account-menu">
    <ul>
      <li><button @click="logout" class="menu-option">Cerrar Sesión</button></li>
      <li><button @click="showChangePassword" class="menu-option">Cambiar Contraseña</button></li>
      <li><button @click="requestAccountClosure" class="menu-option">Solicitar Cierre de Cuenta</button></li>
    </ul>
  </div>
</div>







    <!-- Formulario para cambiar la contraseña (solo si se selecciona) -->
    <div v-if="showPasswordForm" class="account-actions">
      <h3>Cambiar Contraseña</h3>
      <form @submit.prevent="changePassword">
        <label for="oldPassword">Contraseña Actual</label>
        <input type="password" v-model="oldPassword" required />

        <label for="newPassword">Nueva Contraseña</label>
        <input type="password" v-model="newPassword" required />

        <label for="confirmPassword">Confirmar Nueva Contraseña</label>
        <input type="password" v-model="confirmPassword" required />

        <button type="submit" class="action-button">Actualizar Contraseña</button>
        <button type="button" @click="cancelChangePassword" class="cancel-button">Cancelar</button>
   
      </form>
    </div>

    


      <h2>Lista de Señales de Operaciones</h2>

    <!-- Resumen de operaciones -->
    <div class="operations-summary">
      <h3>Resumen de Operaciones</h3>
      <div class="summary-card">
        <p>Operaciones Ganadas (Closed): <strong>{{ closedOrders.length }}</strong></p>
        <p>Operaciones Perdidas (Terminadas): <strong>{{ lostOrders.length }}</strong></p>
        <p>Operaciones Abiertas (Open): <strong>{{ openOrders.length }}</strong></p>
        <p>Promedio (Ganadas/Perdidas): 
          <strong>{{ calculateWinLossRatio }}</strong>
        </p>
      </div>
    </div>


      <!-- Contenedor de Pestañas -->
      <div class="tabs">
      <button @click="activeTab = 'open'" :class="{ active: activeTab === 'open' }">Operaciones Abiertas</button>
      <button @click="activeTab = 'closed'" :class="{ active: activeTab === 'closed' }">Operaciones Ganadas</button>
      <button @click="activeTab = 'lost'" :class="{ active: activeTab === 'lost' }">Operaciones Perdidas</button>
      <button @click="activeTab = 'economicCalendar'" :class="{ active: activeTab === 'economicCalendar' }">Calendario Económico</button>
      <button @click="activeTab = 'marketHolidays'" :class="{ active: activeTab === 'marketHolidays' }">Días Festivos de Mercado</button>
      <button @click="activeTab = 'classes'" :class="{ active: activeTab === 'classes' }">Clases Programadas</button>

      </div>

  

      <!--Dias Festivos-->
      <div v-if="activeTab === 'marketHolidays'" class="market-holidays">
  <h3>Días Festivos de Mercado</h3>
  <div v-if="marketHolidays.today.length === 0" class="no-holidays">
    <p>No hay días festivos hoy.</p>
  </div>
  <div v-if="marketHolidays.week.length > 0" class="week-holidays">
    <h4>Próximos días festivos esta semana:</h4>
    <ul>
      <li v-for="holiday in marketHolidays.week" :key="holiday.id" class="holiday-item">
        <span class="holiday-date">{{ formatDate(holiday.date) }}</span> -
        <span class="holiday-name">{{ holiday.name }}</span>
        <span v-if="holiday.status === 'early-close'" class="holiday-status early-close">(Cierre temprano: {{ holiday.close_time }})</span>
        <span v-if="holiday.status === 'closed'" class="holiday-status closed">(Mercado cerrado)</span>
      </li>
    </ul>
  </div>
  <div v-if="marketHolidays.month.length > 0" class="month-holidays">
    <h4>Días festivos este mes:</h4>
    <ul>
      <li v-for="holiday in marketHolidays.month" :key="holiday.id" class="holiday-item">
        <span class="holiday-date">{{ formatDate(holiday.date) }}</span> -
        <span class="holiday-name">{{ holiday.name }}</span>
        <span v-if="holiday.status === 'early-close'" class="holiday-status early-close">(Cierre temprano: {{ holiday.close_time }})</span>
        <span v-if="holiday.status === 'closed'" class="holiday-status closed">(Mercado cerrado)</span>
      </li>
    </ul>
  </div>
</div>




<div v-if="activeTab === 'economicCalendar'" class="economic-calendar">
  <h3>Calendario Económico</h3>
  <div v-if="economicEvents.today.length === 0" class="no-events">
    <p>No hay eventos económicos importantes hoy.</p>
  </div>
  <div v-if="economicEvents.today.length > 0" class="today-events">
    <h4>Eventos de Hoy:</h4>
    <ul>
      <li v-for="event in economicEvents.today" :key="event.id" class="event-item">
        <!-- Mostrar la fecha junto con el día de la semana -->
        <span class="event-date">{{ formatDate(event.event_date) }} - {{ event.day_of_week }}</span> -
        <span class="event-time">{{ formatTime(event.event_time) }}</span> -
        <span class="event-name">{{ event.event_name }}</span>
        <span class="event-country">({{ event.country }})</span>
      </li>
    </ul>
  </div>
  <div v-if="economicEvents.upcoming.length > 0" class="upcoming-events">
    <h4>Próximos eventos importantes:</h4>
    <ul>
      <li v-for="event in economicEvents.upcoming" :key="event.id" class="event-item">
        <!-- Mostrar la fecha junto con el día de la semana -->
        <span class="event-date">{{ formatDate(event.event_date) }} - {{ event.day_of_week }}</span> -
        <span class="event-time">{{ formatTime(event.event_time) }}</span> -
        <span class="event-name">{{ event.event_name }}</span>
        <span class="event-country">({{ event.country }})</span>
      </li>
    </ul>
  </div>
</div>





<div v-if="activeTab === 'classes'" class="classes-schedule">
  <h3>Horario de Clases</h3>

  <!-- Mensaje para cuando no hay clases -->
  <div v-if="todayClasses.length === 0 && upcomingClasses.length === 0" class="no-classes">
    <p>No hay clases programadas para esta semana.</p>
  </div>

  <!-- Clases de hoy -->
  <div v-else-if="todayClasses.length > 0" class="today-classes">
    <h4>Clases de Hoy:</h4>
    <div class="classes-container">
      <div v-for="classItem in todayClasses" :key="classItem.id" 
           :class="{'class-item': true, 'today': true, 'live': classItem.is_live}">
        <span class="class-day">{{ getDayOfWeek(classItem.class_date) }}</span> -
        <span class="class-title">{{ classItem.title }}</span>
        <span class="class-time">({{ formatTime(classItem.class_time) }})</span>
        <span class="class-level">- Nivel: {{ classItem.level }}</span>
      </div>
    </div>
  </div>

  <!-- Clases próximas -->
  <div v-if="upcomingClasses.length > 0" class="upcoming-classes">
    <h4>Próximas Clases:</h4>
    <div class="classes-container">
      <div v-for="classItem in upcomingClasses" :key="classItem.id" 
           :class="{'class-item': true, 'upcoming': true, 'live': classItem.is_live}">
        <span class="class-date">{{ formatDate(classItem.class_date) }} - {{ getDayOfWeek(classItem.class_date) }}</span> -
        <span class="class-title">{{ classItem.title }}</span>
        <span class="class-time">({{ formatTime(classItem.class_time) }})</span>
        <span class="class-level">- Nivel: {{ classItem.level }}</span>
      </div>
    </div>
  </div>
</div>





      <!-- Mostrar órdenes abiertas -->
      <div v-if="activeTab === 'open'">

      <div v-if="openOrders.length === 0">No hay operaciones abiertas disponibles</div>
      <div v-if="openOrders.length > 0">
        <h3>Operaciones Abiertas</h3>
        <div class="order-cards">
  <div v-for="order in openOrders" :key="order.id" class="order-card">
    <h4>
      <a 
        :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
        target="_blank" 
        rel="noopener noreferrer"
        class="asset-link">
        {{ order.asset }}
      </a> 
      - <span class="order-status">{{ order.status }}</span>
    </h4>
    <p v-if="order.company_name" class="company-name">
      {{ order.company_name }}
    </p>
 
      <p 
         class="current-price" 
         @click="openAssetModal(order.asset)"
         style="cursor: pointer; text-decoration: underline;">
         Precio Actual: {{ order.current_price || 'Cargando...' }}
      </p>



    <p class="price-variation" v-if="order.current_price && order.target_price_1">
      <span 
        :class="['price-variation-item', { negative: (order.target_price_1 - order.current_price) > 0 }]">
        Variación hasta TP1: 
        {{ (order.target_price_1 - order.current_price).toFixed(2) }} 
        ({{ ((order.target_price_1 - order.current_price) / order.current_price * 100).toFixed(2) }}%)
      </span>
    </p>

    <p class="price-variation" v-if="order.current_price && order.entry_min">
      <span 
        :class="['price-variation-item', { negative: (order.current_price - order.entry_min) < 0 }]">
        Variación desde el Precio de Compra: 
        {{ (order.current_price - order.entry_min).toFixed(2) }} 
        ({{ ((order.current_price - order.entry_min) / order.entry_min * 100).toFixed(2) }}%)
      </span>
    </p>



    <div class="order-details">
      <p>Tipo de Orden: <span>{{ order.type }}</span></p>
      <p>Precio Minimo de Compra: <span>{{ order.entry_min }}</span></p>
      <p>Precio Maximo de Compra: <span>{{ order.entry_max }}</span></p>
<!--      <p>Salir en Perdida: <span>{{ order.stop_loss }}</span></p>  -->
      <p>Precio Objetivo 1 : <span>{{ order.target_price_1 }}</span></p>
      <p>Precio Objetivo 2 : <span>{{ order.target_price_2 }}</span></p>
      <p>Fecha de Inicio: <span>{{ formatDate(order.start_date) }}</span></p>
    </div>


 <!-- Mostrar recompras asociadas -->
<div v-if="order.recompras && order.recompras.length > 0">
  <h5>Recompras Asociadas:</h5>
  <ul class="recompras-list">
    <li v-for="recompra in order.recompras" :key="recompra.id" class="recompra-item">
      <div class="recompra-info">
        <span class="recompra-price">Precio: ${{ recompra.price }}</span>
        <span class="recompra-date">{{ formatDate(recompra.created_at) }}</span>
      </div>
    </li>
  </ul>
</div>


    <p v-if="order.comment" class="operation-notes">
      <strong>Notas de la Operación:</strong> {{ order.comment }}
    </p>
          </div>
        </div>
      </div>

    </div>





 <!-- Modal para mostrar datos del activo -->
<div v-if="showAssetModal" class="modal">
  <div class="modal-content">
    <h3>Detalles del Activo</h3>
    <div class="modal-data">
      <p><strong>Precio Actual:</strong> {{ activeAssetData?.currentPrice }}</p>
      <p><strong>Precio Máximo del Día:</strong> {{ activeAssetData?.highPriceOfDay }}</p>
      <p><strong>Precio Mínimo del Día:</strong> {{ activeAssetData?.lowPriceOfDay }}</p>
      <p><strong>Precio de Apertura:</strong> {{ activeAssetData?.openPriceOfDay }}</p>
      <p><strong>Cierre Anterior:</strong> {{ activeAssetData?.previousClosePrice }}</p>
    </div>
    <button class="close-btn" @click="closeAssetModal">Cerrar</button>
  </div>
</div>


  
      <!-- Mostrar órdenes cerradas con ganancia -->
      <div v-if="activeTab === 'closed'">
      
      <div v-if="closedOrders.length > 0">
        <h3>Operaciones Cerradas con Ganancia</h3>
        <div class="order-cards">
          <div v-for="order in closedOrders" :key="order.id" class="order-card">
            <h4>
      <a 
        :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
        target="_blank" 
        rel="noopener noreferrer"
        class="asset-link">
        {{ order.asset }}
      </a> 
      - <span class="order-status">{{ order.status }}</span>
    </h4>
    <p v-if="order.company_name" class="company-name">
      {{ order.company_name }}
    </p>
    
    <div class="order-details">
      <p>Tipo de Orden: <span>{{ order.type }}</span></p>
      <p>Precio Minimo de Compra: <span>{{ order.entry_min }}</span></p>
      <p>Precio Maximo de Compra: <span>{{ order.entry_max }}</span></p>
      <p>Salir en Perdida: <span>{{ order.stop_loss }}</span></p>
      <p>Precio Objetivo 1 (+0.20): <span>{{ order.target_price_1 }}</span></p>
      <p>Precio Objetivo 2 (+0.30): <span>{{ order.target_price_2 }}</span></p>
      <p>Fecha de Inicio: <span>{{ formatDate(order.start_date) }}</span></p>
      <p>Fecha de Venta: <span>{{ formatDate(order.end_date) }}</span></p>
    </div>
    <p v-if="order.comment" class="operation-notes">
      <strong>Notas de la Operación:</strong> {{ order.comment }}
    </p>
          </div>
        </div>
      </div>

  </div>


      <!-- Mostrar operaciones terminadas (perdidas) -->
      <div v-if="activeTab === 'lost'">

      <div v-if="lostOrders.length > 0">
        <h3>Operaciones Perdidas</h3>
        <div class="order-cards"><div class="option"></div>
          <div v-for="order in lostOrders" :key="order.id" class="order-card">
            <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>
            <p>Tipo: {{ order.type }}</p>
            <p>Precio Min de Entrada: {{ order.entry_min }}</p>
            <p>Precio Max de Entrada: {{ order.entry_max }}</p>
            <p>Salida en Perdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Iniciada: {{ formatDate(order.start_date) }}</p>
            <p>Fecha de Perdida: {{ formatDate(order.end_date) }}</p>
  
            <p v-if="order.comment">Comentarios de la Operación: {{ order.comment }}</p>
          </div>
        </div>
      </div>
    </div>




   
      <!-- Pie de página -->
      <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>


   </div> 
 
  </template>
  
  
  <script>
  import axios from "axios";
  import moment from "moment";

  export default {
    data() {
      return {

        todayClasses: [],
        upcomingClasses: [],



        activeAssetData: null, // Información del activo seleccionado
        showAssetModal: false, // Controla la visibilidad del modal


        socket: null,
        activeAsset: null, // Activo seleccionado
        isPremium: false, // Estado para verificar si el usuario es premium
        premiumCheckInterval: null, // Variable para manejar el intervalo
        isLiveStreamActive: false,
        liveStreamCheckInterval: null,
        openOrders: [],
        closedOrders: [],
        lostOrders: [], 
        activeTab: 'open',
        marketHolidays: {
        today: [],
        week: [],
        month: [],
        },
        economicEvents: {
        today: [],
        upcoming: [],
      },
        menuOpen: false, // Estado del menú desplegable
        showPasswordForm: false, // Estado para mostrar el formulario de cambio de contraseña
        oldPassword: "",
        isPhoneVerified: false,
        newPassword: "",
        confirmPassword: "", 
        premiumExpirationDate: null,
        showExpiredMessage: false,
        formattedExpirationDate: '',
        daysRemaining: 0,
      };
    },
    computed: {

    // Verifica si la suscripción está vencida
    isSubscriptionExpired() {
         if (!this.premiumExpirationDate) return true;
        const now = moment();
         const expirationDate = moment(this.premiumExpirationDate);
     return now.isAfter(expirationDate); // Devuelve true si la membresía está vencida
    },


  calculateWinLossRatio() {
    if (this.closedOrders.length === 0 && this.lostOrders.length === 0) {
      return "N/A";
    }
    return (this.closedOrders.length / (this.lostOrders.length || 1)).toFixed(2);
  },

 
  },
    created() {
          this.checkPremiumStatus();
          this.fetchPremiumExpiration();  
          this.checkLiveStreamStatus(); 
          this.checkPhoneVerification();   
          this.fetchMarketHolidays(); 
          this.fetchEconomicEvents();
          this.fetchOrders();  
          this.fetchClasses(); 
         
          
    },

    mounted() {
    this.isLiveStreamActive = localStorage.getItem("isStreamingActive") === 'true';  // Obtenemos el valor del localStorage
    this.startLiveStreamCheck();
    this.startPremiumCheck();
    



   


  },



    beforeUnmount() {
    // Limpia el intervalo al destruir el componente
    if (this.liveStreamCheckInterval) {
      clearInterval(this.liveStreamCheckInterval);
    }
    // Limpia el intervalo de la verificación premium al destruir el componente
    if (this.premiumCheckInterval) {
      clearInterval(this.premiumCheckInterval);
    }


    if (this.socket) {
      this.socket.close();  // Cierra la conexión WebSocket
    }

  },


    methods: {


      initializeWebSocket() {
      this.socket = new WebSocket("wss://socket.fortunia.org");

      this.socket.onopen = () => {
        console.log("Conectado al WebSocket");

        // Suscribirse al activo activo si existe
        const activeAsset = this.getActiveAsset();
        if (activeAsset) {
          this.subscribeToAsset(activeAsset);
        }
      };

      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (Array.isArray(data)) {
          // Si se recibe un conjunto de actualizaciones
          data.forEach(({ symbol, price }) => this.updateOrderPrice(symbol, price));
        } else {
          const { symbol, price } = data;
          if (symbol && price) {
            this.updateOrderPrice(symbol, price);
          } else {
            console.warn("Datos incompletos recibidos del WebSocket:", data);
          }
        }
      };

      this.socket.onerror = (error) => {
        console.error("Error en el WebSocket:", error);
      };

      this.socket.onclose = () => {
        console.log("Conexión WebSocket cerrada");
      };
    },

    // Enviar una suscripción para recibir actualizaciones de un activo
    subscribeToAsset(asset) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ action: "subscribe", asset }));
        console.log("Suscrito al activo:", asset);
      }
    },

    // Obtener el activo activo de la pestaña
    getActiveAsset() {
      // Verifica si existen órdenes abiertas
      if (this.openOrders && this.openOrders.length > 0) {
        // Busca el activo de la primera orden abierta con un activo definido
        const activeOrder = this.openOrders.find((order) => order.asset);
        // Si se encuentra una orden válida con un activo
        if (activeOrder && activeOrder.asset) {
          this.activeAsset = activeOrder.asset; // Guardar el activo activo
          return activeOrder.asset;
        }
      }
      // Si no se encuentra un activo, devuelve null o el valor que desees
      return null;
    },

    // Comprobar si el activo es el que está actualmente abierto
    isActiveAsset(asset) {
      console.log("Comparando el activo:", asset, "con el activo de la pestaña:", this.activeAsset);
      return asset === this.activeAsset; // Comparación directa de activos
    },

    // Actualizar el precio de la orden correspondiente
    updateOrderPrice(symbol, price) {
      console.log("Actualizando precio para el activo:", symbol, "con precio:", price);

      // Encuentra y actualiza la orden que coincide con el símbolo recibido
      const orderToUpdate = this.openOrders.find((order) => order.asset === symbol);
      if (orderToUpdate) {
        orderToUpdate.current_price = price; // Actualiza el precio
        console.log("Precio actualizado para la orden:", orderToUpdate);
      } else {
        console.log("No se encontró ninguna orden abierta con el símbolo:", symbol);
      }
    },

    
    //Fin de socket
  



    toggleMenu() {
      this.menuOpen = !this.menuOpen; // Cambiar el estado del menú
    },
    showChangePassword() {
      this.showPasswordForm = true; // Mostrar el formulario de cambio de contraseña
    },

    cancelChangePassword() {
      this.showPasswordForm = false; // Ocultar el formulario
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = ""; // Limpiar los campos
    },

    verifyPhoneNumber() {
    window.open("https://www.fortunia.org/verify", "_blank"); // Abrir el enlace en una nueva pestaña
  },

  async fetchMarketHolidays() {
    try {
      const todayResponse = await fetch('https://api.fortunia.org/api/market-holidays/today');
      const weekResponse = await fetch('https://api.fortunia.org/api/market-holidays/week');
      const monthResponse = await fetch('https://api.fortunia.org/api/market-holidays/month');

      const todayData = await todayResponse.json();
      const weekData = await weekResponse.json();
      const monthData = await monthResponse.json();

      if (todayData.success) this.marketHolidays.today = todayData.holidays;
      if (weekData.success) this.marketHolidays.week = weekData.holidays;
      if (monthData.success) this.marketHolidays.month = monthData.holidays;
    } catch (error) {
      console.error('Error al cargar los días festivos:', error);
    }
  },


// Método para verificar si el usuario es premium
async checkPremiumStatus() {
  console.log("Verificando estado premium...");
  try {
    const response = await axios.get("https://api.fortunia.org/api/users/is-premium", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // Verifica que la respuesta contenga los datos esperados
    if (response.data && typeof response.data.isPremium === "boolean") {
      this.isPremium = response.data.isPremium;

      if (this.isPremium) {
        this.fetchPremiumExpiration();
       
      } else {
        this.resetPremiumStatus();
      }
    } else {
      console.warn("Respuesta inesperada de la API:", response.data);
      this.resetPremiumStatus();
    }
  } catch (error) {
    console.error("Error al verificar el estado premium:", error);
    this.resetPremiumStatus();
  }
},

// Método auxiliar para restablecer variables de estado premium
resetPremiumStatus() {
  this.isPremium = false;
  this.premiumExpirationDate = null;
  this.formattedExpirationDate = '';
  this.showExpiredMessage = true;

  // Limpiar las órdenes
  this.openOrders = [];
  this.closedOrders = [];
  this.lostOrders = [];
},


async fetchOrders() {
  try {

   

    // Intentar obtener las órdenes
    const response = await axios.get("https://api.fortunia.org/api/operations", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    if (response.data && Array.isArray(response.data)) {
      // Si hay órdenes, procesarlas
      this.openOrders = response.data
        .filter((order) => order.status === "open")
        .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

      this.closedOrders = response.data
        .filter((order) => order.status === "closed")
        .sort((a, b) => {
          const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
          if (endDateDiff !== 0) return endDateDiff; 
          return new Date(b.start_date) - new Date(a.start_date);
        });

    } 

    // Obtener y ordenar las órdenes terminadas (perdidas)
    const lostResponse = await axios.get("https://api.fortunia.org/api/operations/terminada", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    if (lostResponse.data && Array.isArray(lostResponse.data)) {
      this.lostOrders = lostResponse.data.sort((a, b) => {
        const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
        if (endDateDiff !== 0) return endDateDiff;
        return new Date(b.start_date) - new Date(a.start_date);
      });
    }

  } catch (error) {
    console.error("Error fetching orders", error);
    //alert("Unable to fetch orders. Please try again later.");
  }
},


async checkPhoneVerification() {
      const token = localStorage.getItem("token");
      try {
        // Verificar si el teléfono está verificado
        const response = await axios.get("https://api.fortunia.org/api/auth/check-phone-verification", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Actualizar el estado de verificación
        this.isPhoneVerified = response.data.phoneVerified;
      } catch (error) {
        console.error("Error al verificar el teléfono:", error);
        // En caso de error, asumir que el teléfono no está verificado
        this.isPhoneVerified = false;
      }
    },


  async fetchPremiumExpiration() {
  try {
    // Realizar la llamada al endpoint para obtener la información de la suscripción premium
    const response = await axios.get("https://api.fortunia.org/api/update-users/premium-expiration", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    // Validar la respuesta
    const data = response.data;

    if (!data || !data.premiumExpirationDate) {
      console.warn('El usuario no tiene acceso premium asignado.');
      this.premiumExpirationDate = null;
      this.formattedExpirationDate = '';
      this.daysRemaining = 0;
      this.showExpiredMessage = true; // Mostrar mensaje de suscripción expirada
      return;
    }

    // Manejar información de la suscripción premium
    this.premiumExpirationDate = data.premiumExpirationDate;
    this.daysRemaining = data.daysRemaining;
    this.formattedExpirationDate =
      this.premiumExpirationDate === 'Ilimitado'
        ? 'Acceso premium ilimitado'
        : moment(this.premiumExpirationDate).format('YYYY-MM-DD HH:mm:ss');

    console.log('Fecha de expiración:', this.formattedExpirationDate);
    console.log('Días restantes:', this.daysRemaining);

    // Decidir si mostrar o no el mensaje de suscripción expirada
    if (this.daysRemaining > 0 || this.premiumExpirationDate === 'Ilimitado') {
      this.showExpiredMessage = false;
     // this.fetchOrders(); // Realizar acciones adicionales para usuarios premium
      this.initializeWebSocket();
    } else {
      this.showExpiredMessage = true;
    }
  } catch (error) {
    console.error("Error al obtener la fecha de expiración del acceso premium:", error);
    this.premiumExpirationDate = null;
    this.formattedExpirationDate = '';
    this.daysRemaining = 0;
    this.showExpiredMessage = true; // En caso de error, mostrar el mensaje de suscripción expirada
  }
},




      formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString('en-US', options);
      },


 logout() {
  axios
    .post("https://api.fortunia.org/api/auth/logout")
    .then(() => {
      // Limpia el localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("role");

      // Redirige al usuario a la página de inicio
      window.location.href = "/";
    })
    .catch((error) => {
      console.error("Error al cerrar sesión:", error);

      // Asegúrate de limpiar y redirigir incluso si hay un error
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      window.location.href = "/";
    });
},

async changePassword() {
      if (this.newPassword !== this.confirmPassword) {
        alert("Las contraseñas no coinciden.");
        return;
      }

      try {
        const response = await axios.post("https://api.fortunia.org/api/auth/change-password", {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });

        if (response.data.success) {
          alert("Contraseña cambiada exitosamente.");
          this.showPasswordForm = false;
        } else {
          alert("Error al cambiar la contraseña.");
        }
      } catch (error) {
        console.error("Error changing password", error);
        alert("Ocurrió un error. Por favor intente de nuevo.");
      }
    },





  requestAccountClosure() {
  fetch('https://api.fortunia.org/api/users/request-account-closure', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Asegúrate de agregar el token de autenticación
    },
  })
  .then(response => response.json())
  .then(data => {
    if (data.message) {
      alert(data.message); // Muestra el mensaje de éxito
    }
  })
  .catch(error => {
    console.error('Error al solicitar el cierre de cuenta:', error);
    alert('Ocurrió un error al procesar la solicitud.');
  });
},

async createSubscription(period) {
    try {
      // Realizar la solicitud para crear la sesión de Stripe con el período elegido
      const response = await axios.post("https://api.fortunia.org/api/stripe/create-subscription", 
        { period },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // Asegúrate de enviar el token de autenticación
          }
        }
      );

      // Redirigir al usuario a la URL de la sesión de Stripe
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error al crear la sesión de suscripción:", error);
      alert("Hubo un error al procesar la suscripción. Por favor, intenta de nuevo.");
    }
  },

 

 
 // Método para verificar el estado de la transmisión
 async checkLiveStreamStatus() {


   // Verificar si el usuario tiene acceso premium antes de continuar
   if (!this.isPremium) {
        console.log("Acceso denegado: El usuario no es premium.");
        return; // Detiene la ejecución si no es premium
      }


  try {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error("Token de autenticación no encontrado");
      return;
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    };

    const response = await axios.get("https://api.fortunia.org/api/owncast/status", config);

    // Depuración para ver la respuesta de la API
    console.log("Respuesta de la API:", response.data);

    const isCurrentlyLive = response.data.status === "En línea"; // Verificar si está en línea
    localStorage.setItem("isStreamingActive", isCurrentlyLive); // Guardar estado en localStorage

    if (isCurrentlyLive !== this.isLiveStreamActive) {
      this.isLiveStreamActive = isCurrentlyLive;

      // Alerta si la transmisión está activa
      if (isCurrentlyLive) {
        this.showLiveStreamAlert();
      }
    }
  } catch (error) {
    console.error("Error al verificar el estado de la transmisión:", error);
    this.isLiveStreamActive = false; // En caso de error, establecemos la transmisión como inactiva
  }
},






     // Método para iniciar la verificación periódica
     startLiveStreamCheck() {
      // Limpia cualquier intervalo existente
      if (this.liveStreamCheckInterval) {
        clearInterval(this.liveStreamCheckInterval);
      }

      // Configura una verificación periódica cada 5 segundos (5000 ms)
      this.liveStreamCheckInterval = setInterval(() => {
        this.checkLiveStreamStatus();
      }, 5000);
    },


    showLiveStreamAlert() {
      setTimeout(() => {
       // alert("¡Tenemos una clase en vivo en este momento! ¡No te la pierdas!");
      }, 1000);
    },

    // Navega a la página de transmisiones
    goToLiveStream() {
      window.location.href = "/transmissions";
    },



    startPremiumCheck() {
    // Limpia cualquier intervalo existente para evitar duplicados
    if (this.premiumCheckInterval) {
      clearInterval(this.premiumCheckInterval);
    }

    // Configura el intervalo para verificar el estado premium cada 5 segundos
    this.premiumCheckInterval = setInterval(() => {
      this.checkPremiumStatus();
    }, 5000);
  },



    // Método para detener la verificación periódica
    stopPremiumCheck() {
      if (this.premiumCheckInterval) {
        clearInterval(this.premiumCheckInterval);
      }
    },


    async fetchEconomicEvents() {
    try {
        // Solicitar los eventos económicos del día actual y los próximos
        const response = await fetch('https://api.fortunia.org/api/calendar/economic-calendar');
        
        const data = await response.json();

        // Verificar que la respuesta sea exitosa
        if (data.success) {
            // Asignar los eventos del día y los próximos a las variables correspondientes
            this.economicEvents.today = data.today;
            this.economicEvents.upcoming = data.upcoming;
        } else {
            console.error('Error en la respuesta: ', data.error);
        }
    } catch (error) {
        console.error('Error al cargar los eventos económicos:', error);
    }
},


formatTime(time) {
    const [hour, minute] = time.split(':');
    return `${hour}:${minute}`;
},


// Obtener clases desde el endpoint
async fetchClasses() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get("https://api.fortunia.org/api/clases/classes-schedule", {
      headers: {
        "Authorization": `Bearer ${token}`  
      }
    });
    
    if (response.data.success) {
      const { today, upcoming } = response.data;
      this.todayClasses = today;
      this.upcomingClasses = upcoming;
    }
  } catch (error) {
    console.error("Error al obtener las clases:", error);
  }
},


getDayOfWeek(date) {
    const daysOfWeek = [
      'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ];
    const dayIndex = new Date(date).getDay();
    return daysOfWeek[dayIndex];
  },




 // Método para abrir el modal del activo
 openAssetModal(asset) {
    this.activeAsset = asset; // Guarda el activo seleccionado
    this.showAssetModal = true; // Muestra el modal

    // Realiza la solicitud para obtener los detalles del activo
    axios.get(`https://api.fortunia.org/api/assets/quote?symbol=${asset}`)
      .then((response) => {
        this.activeAssetData = response.data; // Almacena los datos del activo
      })
      .catch((error) => {
        console.error("Error al cargar los detalles del activo:", error);
        this.activeAssetData = null; // Limpia los datos si ocurre un error
      });
  },

  // Método para cerrar el modal
  closeAssetModal() {
    this.showAssetModal = false;
    this.activeAssetData = null; // Limpia los datos del modal
  },






  },

  watch: {
    // Si el usuario inicia sesión o se actualiza el token
    'localStorage.token': function() {
      this.checkLiveStreamStatus(); // Verifica el estado de la transmisión al cambiar el token
      this.checkPremiumStatus();
    },

     // Controla el estado de la suscripción premium
  isPremium(newValue) {
    if (!newValue) {
      this.showExpiredMessage = true; // Mostrar mensaje si no es premium
    } else {
      this.showExpiredMessage = false; // Ocultar mensaje si es premium
    }
  },

  // Controla la fecha de expiración de la suscripción premium
  premiumExpirationDate(newValue) {
    if (!newValue || this.isSubscriptionExpired) {
      this.showExpiredMessage = true; // Mostrar mensaje si la suscripción ha expirado
    } else {
      this.showExpiredMessage = false; // Ocultar mensaje si la suscripción es válida
    }
  },

  // Agregar lógica para controlar el estado de la transmisión en vivo
  liveStreamStatus(newStatus) {
    if (newStatus === 'inactive') {
      this.showLiveStreamMessage = true; // Mostrar mensaje si la transmisión está inactiva
    } else {
      this.showLiveStreamMessage = false; // Ocultar mensaje si la transmisión está activa
    }
  }



  },

  

};

</script>




  
  
<style scoped>

/* General */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f7f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3, h4 {
  color: #333;
}

strong {
  font-weight: bold;
}

/* Contenedor principal */
.home-page {
  padding: 20px;
}

/* Estilo para el mensaje de expiración del acceso premium */
/* Estilo general del mensaje */
.subscription-expired-message {
  background: linear-gradient(135deg, #ffebee, #ffcdd2);
  border: 1px solid #f44336;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.subscription-expired-message h2 {
  color: #d32f2f;
  font-size: 24px;
  margin-bottom: 10px;
}

.subscription-expired-message p {
  font-size: 16px;
  margin-bottom: 15px;
}

.subscription-expired-message strong {
  color: #b71c1c;
}

/* Opciones de suscripción */
.subscription-expired-message {
  border: 2px solid #ff6f61;
  background-color: #fff4f2;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #333;
}

.expired-title {
  color: #d9534f;
  font-size: 24px;
  margin-bottom: 10px;
}

.subscription-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.option {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  text-align: center;
}

.option:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.popular-tag {
  background-color: #ff6f61;
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
}

.option-price {
  font-size: 16px;
  color: #555;
}

.savings {
  color: #28a745;
  font-weight: bold;
  margin-left: 5px;
}

.contact-message {
  font-size: 14px;
  color: #666;
  margin-top: 15px;
}

.premium-expiration {
  border: 2px solid #5cb85c;
  background-color: #f2fdf4;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.premium-expiration p {
  font-size: 16px;
  color: #333;
}

strong {
  color: #d9534f;
}



/* Mensaje de contacto */
.contact-message {
  font-size: 14px;
  color: #d32f2f;
  margin-top: 15px;
}

/* Contenedor del menú */
.account-menu-container {
  position: relative;
  display: inline-block;
}

/* Botón principal */
.account-menu-button {
  background: linear-gradient(135deg, #42b983, #36a277);
  color: #fff;
  padding: 12px 25px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-menu-button:hover {
  background: linear-gradient(135deg, #36a277, #42b983);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Menú desplegable */
.account-menu {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background: #ffffff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 10px 0;
  width: 260px;
  z-index: 10;
  transform: translateY(-10px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.account-menu-container:hover .account-menu {
  transform: translateY(0);
  opacity: 1;
}

/* Opciones del menú */
.menu-option {
  background-color: transparent;
  border: none;
  padding: 12px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
}

.menu-option:hover {
  background-color: #f8f8f8;
  padding-left: 25px;
}

/* Responsivo */
@media (max-width: 768px) {
  .account-menu-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .account-menu {
    width: 220px;
    top: 50px;
  }
}



/* Formulario de cambio de contraseña */
.account-actions {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.account-actions h3 {
  margin-bottom: 15px;
  color: #333;
}

.account-actions form {
  display: grid;
  grid-gap: 10px;
}

.account-actions label {
  font-weight: bold;
}

.account-actions input {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.account-actions button {
  background-color: #42b983;
  color: white;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.account-actions button:hover {
  background-color: #36a277;
}

.cancel-button {
  background-color: #f1f1f1;
  color: #333;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* Resumen de operaciones */
.operations-summary {
  margin-top: 30px;
}

.summary-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.summary-card p {
  font-size: 1.1rem;
}

/* Pestañas de operaciones */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  border-bottom: 2px solid #ddd;
}

.tabs button {
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tabs button.active {
  color: #42b983;
  border-bottom: 2px solid #42b983;
}

.tabs button:hover {
  color: #36a277;
}




/* Pie de Página */
.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #030303;
}

.footer p {
  margin: 5px 0;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.footer nav ul li {
  margin: 5px 0;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 14px;
}

.footer nav ul li a:hover {
  color: #000;
}

/* Estilos específicos para pantallas pequeñas */
@media (max-width: 768px) {
  .footer {
    font-size: 12px;
    padding: 10px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer nav ul li a {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
    padding: 8px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 5px;
  }

  .footer nav ul li a {
    font-size: 10px;
  }
}

/* Estilo adicional para mejorar la visualización */
.footer p strong {
  font-weight: bold;
}





/* Responsividad */
@media screen and (max-width: 768px) {
  .order-cards {
    grid-template-columns: 1fr 1fr;
  }

  .tabs {
    flex-direction: column;
    align-items: center;
  }

  .order-cards {
    grid-template-columns: 1fr;
  }

  .summary-card {
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .tabs {
    flex-direction: column;
    align-items: stretch;
  }

  .order-cards {
    grid-template-columns: 1fr;
  }

  .account-actions {
    padding: 15px;
  }

  .account-menu-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer nav ul {
    flex-direction: column;
  }
}


/* Alerta de Telefono no verifica */


.alert-message {
  background-color: #ffeb3b;
  color: #000;
  padding: 15px;
  text-align: center;
  border: 1px solid #fbc02d;
  margin-bottom: 20px;
}

.alert-message p {
  margin: 0;
}

.alert-message .verify-link {
  font-weight: bold;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.home-content {
  padding: 20px;
}


/*Estilos para los dias Festivos*/
/* Contenedor principal */
.market-holidays {
  font-family: 'Arial', sans-serif;
  color: #333;
  max-width: 800px;
  margin: 20px auto; /* Centra el contenedor en la página */
  padding: 20px;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Títulos */
.market-holidays h3 {
  font-size: 26px;
  color: #4CAF50;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.market-holidays h4 {
  font-size: 22px;
  color: #2196F3;
  margin-bottom: 15px;
  font-weight: bold;
}

/* Mensaje cuando no hay festivos */
.market-holidays .no-holidays p {
  font-size: 18px;
  color: #757575;
  text-align: center;
}

/* Listas */
.market-holidays ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Elementos individuales */
.market-holidays .holiday-item {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.market-holidays .holiday-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Fechas */
.market-holidays .holiday-date {
  font-weight: bold;
  color: #FF5722;
}

/* Nombres */
.market-holidays .holiday-name {
  font-size: 16px;
  color: #333;
}

/* Estados */
.market-holidays .holiday-status {
  font-size: 14px;
  font-style: italic;
}

.market-holidays .holiday-status.early-close {
  color: #FFC107;
}

.market-holidays .holiday-status.closed {
  color: #F44336;
}

/* Responsividad */
@media (min-width: 768px) {
  .market-holidays .holiday-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}



/* Clases en vivo */

.live-stream-alert {
  background-color: #ffcc00;
  color: #000;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}




/* Contenedor de las tarjetas */
.order-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Ajusta el tamaño mínimo de las tarjetas */
  gap: 20px; /* Espacio entre tarjetas */
  margin: 20px 0; /* Separación vertical del contenedor */
}

/* Tarjetas individuales */
.order-card {
  background-color: #ffffff; /* Fondo blanco limpio */
  border: 1px solid #e6e6e6; /* Borde gris claro */
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  font-family: 'Arial', sans-serif;
}

/* Títulos y enlaces */
.asset-link {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  font-size: 1.2em;
}

.asset-link:hover {
  text-decoration: underline;
}

.order-status {
  font-size: 1em;
  font-weight: bold;
  color: #28a745; /* Verde para indicar estado positivo */
}

/* Detalles de la orden */
.order-details p {
  font-size: 1em;
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-details p span {
  font-weight: bold;
  color: #333;
}

.order-type span {
  color: #17a2b8; /* Azul claro */
}

.order-entry span {
  color: #ff9800; /* Naranja */
}

.order-stop-loss span {
  color: #dc3545; /* Rojo */
}

.order-target span {
  color: #28a745; /* Verde */
}

.order-start-date span {
  color: #6c757d; /* Gris */
}

/* Botón de edición */
.edit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #0056b3;
}

/* Precio actual */
.current-price {
  font-size: 1.2em;
  font-weight: bold;
  color: #2e0ee6;
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

/* Notas de la operación */
.operation-notes {
  background-color: #f0f8ff;
  padding: 10px;
  border-left: 5px solid #007bff;
  font-size: 1.1em;
  color: #333;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.operation-notes strong {
  color: #007bff;
}


/*Calendario Economico */

/* Calendario Económico - Estilo moderno y responsivo */

.economic-calendar {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.economic-calendar h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.no-events,
.today-events,
.upcoming-events {
  margin: 15px 0;
}

.no-events p {
  font-size: 18px;
  color: #888;
  text-align: center;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2d3e50;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}

.event-item {
  background-color: #fff;
  padding: 10px;
  margin: 8px 0;
  border-radius: 8px;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.event-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-time,
.event-name,
.event-country,
.event-date {
  display: block;
  font-size: 14px;
  color: #555;
}

.event-time {
  font-weight: 600;
  color: #007bff;
}

.event-name {
  font-weight: 500;
  color: #333;
  margin-top: 5px;
}

.event-country {
  font-style: italic;
  color: #777;
  font-size: 12px;
}

.event-date {
  font-weight: 600;
  color: #5d6d7e;
  font-size: 15px;
}

/* Responsividad */
@media (max-width: 768px) {
  .economic-calendar {
    margin: 10px;
    padding: 15px;
  }

  .event-item {
    font-size: 13px;
    padding: 8px;
  }

  .event-time,
  .event-name,
  .event-country,
  .event-date {
    font-size: 13px;
  }

  h4 {
    font-size: 18px;
  }

  .economic-calendar h3 {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .economic-calendar {
    margin: 5px;
    padding: 10px;
  }

  .event-item {
    font-size: 12px;
    padding: 6px;
  }

  .event-time,
  .event-name,
  .event-country,
  .event-date {
    font-size: 12px;
  }

  h4 {
    font-size: 16px;
  }

  .economic-calendar h3 {
    font-size: 20px;
  }
}



/* Variaciones de Precios */
.price-variation {
  font-size: 1em;
  margin-top: 10px;
}

.price-variation-item {
  font-weight: bold;
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.price-variation-item.negative {
  color: red; /* Valores negativos */
}

.price-variation-item:not(.negative) {
  color: green; /* Valores positivos */
}



  
/* Estilo para las clases */

/* Estilo general */
.classes-schedule {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
  overflow: hidden;
}

h3 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

h4 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 15px;
}

.no-classes p {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
}

/* Contenedor de las clases */
.classes-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.class-item {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.class-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.class-item .class-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.class-item .class-time,
.class-item .class-level,
.class-item .class-date {
  font-size: 1rem;
  color: #777;
}

/* Clases en vivo */
.live {
  background-color: #ffeb3b;  /* Un color brillante y llamativo */
  border-left: 5px solid #fbc02d;  /* Un color dorado para destacar */
}

.live .class-title {
  color: #c2185b;  /* Un color vibrante para el título */
}

/* Clases del día */
.today {
  background-color: #eaf9e6;
  border-left: 5px solid #4caf50;
}

.today .class-title {
  color: #388e3c;
}

/* Clases próximas */
.upcoming {
  background-color: #e0f7fa;
  border-left: 5px solid #00bcd4;
}

.upcoming .class-title {
  color: #0097a7;
}


/* Respuesta a tamaños pequeños de pantalla */
@media (max-width: 768px) {
  .classes-schedule {
    padding: 15px;
  }

  .class-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .class-item .class-title {
    font-size: 1rem;
  }

  .class-item .class-time,
  .class-item .class-level,
  .class-item .class-date {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .classes-schedule {
    max-width: 100%;
    padding: 10px;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  .class-item {
    padding: 12px;
  }
}




/* Estilo para las recompras asociadas */
.recompras-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.recompra-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.recompra-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.recompra-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.recompra-price {
  font-weight: 600;
  color: #42b983;
  font-size: 1rem;
}

.recompra-date {
  color: #999;
  font-size: 0.9rem;
  text-align: right;
}

.recompras-list h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #2a3d66;
  font-weight: 600;
}



/* Ventana modal con diseño estilizado */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: left;
  animation: scale-in 0.3s ease-in-out;
}

.modal-content h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.modal-data p {
  font-size: 1rem;
  color: #555;
  margin: 8px 0;
}

.modal-data p strong {
  color: #222;
}

.close-btn {
  display: inline-block;
  padding: 10px 20px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.close-btn:hover {
  background-color: #0056b3;
}

/* Animaciones */
@keyframes scale-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsividad */
@media (max-width: 480px) {
  .modal-content {
    padding: 15px;
    font-size: 0.9rem;
  }

  .modal-content h3 {
    font-size: 1.2rem;
  }

  .close-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}





</style>
