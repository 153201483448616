<template>
  <div>
   
    <!-- Logo de la página -->
    <header class="header">
        <img src="https://www.fortunia.org/img/logo.0484ebc9.png" alt="Logo de Fortunia" class="logo" />
        <!-- Enlace para volver atrás -->
        <router-link to="/" class="back-link">← Atrás</router-link>
   </header>

    <!-- Contenido del Acerca de -->
    <main class="main-content">
      <h1 class="title">Acerca de Nosotros</h1>

      <section class="content">
        <p>
          Bienvenidos a nuestro espacio dedicado a compartir análisis, estrategias y reflexiones sobre los mercados financieros. Este sitio web nació de la convicción de que la transparencia y el aprendizaje compartido pueden marcar la diferencia en el camino de las inversiones.
        </p>

        <p>
          Mi experiencia en los mercados de inversión, tanto en criptomonedas como en la bolsa de valores, me ha enseñado que cada operación lleva consigo una lección valiosa. Aquí, publico de forma detallada mis operaciones diarias junto con notas y explicaciones que buscan desglosar la lógica detrás de cada decisión.
        </p>

        <h2>Nuestra Misión</h2>
        <p>
          Nuestra misión es empoderar a otros inversionistas a través de un enfoque honesto y educativo. Este sitio no pretende ofrecer consejos de inversión, sino servir como un recurso donde otros puedan observar, analizar y aprender de mis aciertos y errores, para así desarrollar su propio criterio en los mercados financieros.
        </p>

        <h2>Transparencia y Compromiso</h2>
        <p>
          Sabemos que el mundo de las inversiones puede ser desafiante, y por eso nos esforzamos por ser un ejemplo de transparencia. Aquí encontrarás análisis sinceros, con sus éxitos y fallos, porque creemos que la verdadera enseñanza está en enfrentar ambas caras de la moneda.
        </p>

        <h2>Una Comunidad de Aprendizaje</h2>
        <p>
          Queremos que este espacio sea más que un sitio web. Buscamos construir una comunidad donde inversionistas de todos los niveles puedan aprender y crecer juntos. Las herramientas, artículos y análisis publicados aquí están diseñados para inspirarte a tomar decisiones informadas y conscientes en tu viaje de inversión.
        </p>

        <p>
          Gracias por ser parte de esta iniciativa. Estamos comprometidos a seguir evolucionando y ofreciendo contenido de valor que te ayude a entender mejor los mercados y desarrollar tus propias estrategias. Si tienes preguntas, comentarios o simplemente deseas compartir tus ideas, no dudes en ponerte en contacto con nosotros.
        </p>

        <p><strong>¡Juntos construyamos un futuro financiero más sólido e informado!</strong></p>
      </section>
    </main>

  
    <!-- Pie de página -->
    <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>

  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
/* Estilos generales */
:root {
  --primary-color: #1a73e8;
  --secondary-color: #f5f5f5;
  --text-color: #333;
  --bg-light: #f9f9f9;
  --max-width: 1200px;
  --button-color: #1a73e8;
  --hover-color: #0066cc;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
  background-color: var(--bg-light);
}

.header {
  text-align: center;
  padding: 20px;
  background-color: var(--secondary-color);
}

.logo {
  max-width: 180px;
  height: auto;
}

.main-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.content {
    margin: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
  }


/* Botón Atrás */
.back-link {
  text-align: left;
  margin: 20px;
}

.back-button {
  color: #007bff;
  font-size: 1rem;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #e0f7fa;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
  background-color: #b2ebf2;
  transform: scale(1.1);
}

/* Footer */
.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer nav ul li a:hover {
  color: #000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
    font-size: 1.75rem;
  }

  .about-section h2 {
    font-size: 1.25rem;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .back-button {
    font-size: 1rem;
  }
}
</style>
