<template>
  <div>
    
    <!-- Logo de la página -->
    <header class="header">
        <img src="https://www.fortunia.org/img/logo.0484ebc9.png" alt="Logo de Fortunia" class="logo" />
        <!-- Enlace para volver atrás -->
        <router-link to="/" class="back-link">← Atrás</router-link>
      </header>
  

    <!-- Título del Disclaimer -->
    <h1>Disclaimer</h1>

    <!-- Contenido del Disclaimer -->
    <section class="content">
      <p>
        Este sitio web publica los análisis realizados por mí en base a mi experiencia en las operaciones de trading tanto en criptomonedas como en la bolsa de valores.
        Las decisiones que tome cualquier usuario basándose en esta información son de su propia responsabilidad.
      </p>

      <p>
        No brindamos consejos de inversión, y no somos responsables de las pérdidas que cualquier usuario pueda sufrir.
        La información publicada aquí refleja mis operaciones y análisis personales y no debe considerarse como una recomendación para actuar en los mercados financieros.
      </p>

      <h2>Exención de Responsabilidad Financiera</h2>
      <p>
        Todas las opiniones, análisis y estrategias publicadas son únicamente para fines informativos y educativos.
        No garantizamos resultados específicos ni rentabilidad en el trading o inversiones realizadas por los usuarios.
      </p>

      <h2>Riesgos en los Mercados Financieros</h2>
      <p>
        Operar en los mercados financieros, incluidas criptomonedas y acciones, conlleva riesgos significativos.
        Es posible que pierdas todo tu capital invertido. Asegúrate de entender los riesgos y, si es necesario, busca asesoramiento profesional.
      </p>

      <p>Última actualización: 16 de diciembre de 2024.</p>
    </section>

     <!-- Pie de página reutilizado -->
   
      <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>
      



  </div>
</template>

<script>
export default {
  name: "DisclaimerPage",
};
</script>


<style scoped>
/* Estilos generales */
:root {
  --primary-color: #1a73e8;
  --secondary-color: #f5f5f5;
  --text-color: #333;
  --bg-light: #f9f9f9;
  --max-width: 1200px;
  --button-color: #1a73e8;
  --hover-color: #0066cc;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
  background-color: var(--bg-light);
}

.header {
  text-align: center;
  padding: 20px;
  background-color: var(--secondary-color);
}

.logo {
  max-width: 180px;
  height: auto;
}

.main-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.content {
    margin: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
  }


/* Botón Atrás */
.back-link {
  text-align: left;
  margin: 20px;
}

.back-button {
  color: #007bff;
  font-size: 1rem;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #e0f7fa;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
  background-color: #b2ebf2;
  transform: scale(1.1);
}

/* Footer */
.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer nav ul li a:hover {
  color: #000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
    font-size: 1.75rem;
  }

  .about-section h2 {
    font-size: 1.25rem;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .back-button {
    font-size: 1rem;
  }
}
</style>
