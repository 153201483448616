<template>
  <div class="transmissions-container">
    <div class="header">
      <img class="logo" src="https://www.fortunia.org/img/logo.0484ebc9.png" alt="Fortunia Logo" />
      <h1>Transmisiones en Vivo</h1>
    </div>

    <div class="error" v-if="!isLoggedIn">
      <p>Debes iniciar sesión para ver las transmisiones en vivo.</p>
    </div>

    <div v-else>
      <p>Bienvenido, disfruta de nuestras transmisiones en vivo:</p>
      <div class="video-embed">
        <iframe
          v-if="isLive"
          :src="videoUrl"
          title="Transmisión en Vivo"
          height="500px"
          width="100%"
          referrerpolicy="origin"
          allowfullscreen
        ></iframe>
        <p v-else>No hay transmisiones en vivo disponibles en este momento.</p>
      </div>
    </div>

    <button @click="goToHomePage" class="home-button">Volver al Inicio</button>

 
     <!-- Pie de página -->
     <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>


  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoggedIn: false,
      isLive: false,
      videoUrl: "",
      tokenVerificationInterval: null,
    };
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.verifyToken(token);
    }
    this.fetchLiveStatus(); // Verifica si hay transmisión al cargar el componente
    this.startTokenVerification(); // Inicia la verificación periódica del token
  },
  beforeUnmount() {
    // Detenemos la verificación periódica al destruir el componente
    clearInterval(this.tokenVerificationInterval);
  },
  methods: {
    async verifyToken(token) {
      try {
        const response = await axios.get(
          "https://api.fortunia.org/api/auth/verify-token",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.isLoggedIn = response.data.isValid;
        if (!this.isLoggedIn) {
          this.isLive = false;
          this.videoUrl = "";
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        this.isLoggedIn = false;
      }
    },
    startTokenVerification() {
      const token = localStorage.getItem("token");
      this.tokenVerificationInterval = setInterval(() => {
        if (token) {
          this.verifyToken(token);
        }
      }, 5000); // Verifica cada 5 segundos
    },
    async fetchLiveStatus() {
      try {
        const authToken = localStorage.getItem('token');
        if (!authToken) {
          console.error("Token de autenticación no encontrado");
          return;
        }

        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        };

        const response = await axios.get("https://api.fortunia.org/api/owncast/status", config);
        const isCurrentlyLive = response.data.status === "En línea";

        this.isLive = isCurrentlyLive;

        if (isCurrentlyLive) {
          this.videoUrl = "https://streaming.fortunia.org/embed/video"; // URL de la transmisión en vivo
        }
      } catch (error) {
        console.error("Error al obtener el estado de las transmisiones:", error);
        this.isLive = false; // En caso de error, se asegura que no haya transmisión activa
      }
    },
    goToHomePage() {
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
.transmissions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background-color: #f7f7f7;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.error {
  color: red;
  font-size: 1.2rem;
}

.video-embed {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
}

p {
  margin: 10px 0;
}

.home-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.home-button:hover {
  background-color: #0056b3;
}

.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #030303;
}

.footer p {
  margin: 5px 0;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.footer nav ul li {
  margin: 5px 0;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 14px;
}

.footer nav ul li a:hover {
  color: #000;
}

@media (max-width: 768px) {
  .footer {
    font-size: 12px;
    padding: 10px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer nav ul li a {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
    padding: 8px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 5px;
  }

  .footer nav ul li a {
    font-size: 10px;
  }
}

.footer p strong {
  font-weight: bold;
}
</style>
