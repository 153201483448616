<template>
  <div class="edit-operation-container">
    <h1>Editar Operación</h1>
    <p>Operación ID: {{ operationId }}</p>

    <div v-if="loading" class="loading">Cargando...</div>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>

    <form v-if="!loading && !errorMessage" @submit.prevent="updateOperation" class="operation-form">
      <div class="form-group">
        <label for="asset">Activo</label>
        <input type="text" v-model="operation.asset" id="asset" required />
      </div>
      <div class="form-group">
        <label for="type">Tipo</label>
        <input type="text" v-model="operation.type" id="type" required />
      </div>
      <div class="form-group">
        <label for="entry_min">Entrada Mínima</label>
        <input type="number" v-model="operation.entry_min" step="0.01" id="entry_min" required />
      </div>
      <div class="form-group">
        <label for="entry_max">Entrada Máxima</label>
        <input type="number" v-model="operation.entry_max" step="0.01" id="entry_max" required />
      </div>
      <div class="form-group">
        <label for="stop_loss">Stop Loss</label>
        <input type="number" v-model="operation.stop_loss" step="0.01" id="stop_loss" />
      </div>
      <div class="form-group">
        <label for="target_price_1">Precio Objetivo 1</label>
        <input type="number" v-model="operation.target_price_1" step="0.01" id="target_price_1" />
      </div>
      <div class="form-group">
        <label for="target_price_2">Precio Objetivo 2</label>
        <input type="number" v-model="operation.target_price_2" step="0.01" id="target_price_2" />
      </div>
      <div class="form-group">
        <label for="status">Estado</label>
        <select v-model="operation.status" id="status" required>
          <option value="open">Open</option>
          <option value="closed">Closed</option>
          <option value="terminada">Perdida</option>
        </select>
      </div>
      <div class="form-group">
        <label for="start_date">Fecha de Inicio</label>
        <input type="date" v-model="operation.start_date" id="start_date" required />
      </div>
      <div class="form-group">
        <label for="end_date">Fecha de Finalización</label>
        <input type="date" v-model="operation.end_date" id="end_date" />
      </div>
      <div class="form-group full-width">
        <label for="comment">Comentario</label>
        <textarea v-model="operation.comment" id="comment" rows="5"></textarea>
      </div>
      <div class="form-group full-width">
        <label for="recompras">Recompras (separar por comas)</label>
        <input
          type="text"
          v-model="recomprasInput"
          id="recompras"
          placeholder="Ejemplo: 50.5, 60.3, 72.8"
        />
      </div>
      <div class="form-buttons">
        <button type="submit" class="submit-btn" :disabled="isUpdating">
          {{ isUpdating ? "Actualizando..." : "Actualizar" }}
        </button>
        <button type="button" class="cancel-btn" @click="$router.push('/admin')">
          Cancelar
        </button>
        <button
          @click="deleteOperation"
          type="button"
          class="delete-btn"
          :disabled="isUpdating"
        >
          Eliminar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      operation: {
        asset: "",
        type: "",
        entry_min: "",
        entry_max: "",
        stop_loss: "",
        target_price_1: "",
        target_price_2: "",
        status: "",
        start_date: "",
        end_date: "",
        comment: "",
      },
      recomprasInput: "",
      loading: false,
      isUpdating: false,
      errorMessage: "",
    };
  },
  computed: {
    operationId() {
      return this.$route.params.id;
    },
  },
  watch: {
    "operation.status"(newStatus) {
      if (newStatus === "closed" || newStatus === "terminada") {
        const today = new Date();
        const localDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
          .toISOString()
          .split("T")[0];
        this.operation.end_date = localDate;
      }
    },
  },
  mounted() {
    this.fetchOperation();
  },
  methods: {
    async fetchOperation() {
      this.loading = true;
      try {
        const response = await axios.get(
          `https://api.fortunia.org/api/operations/${this.operationId}`
        );
        if (response.status === 200) {
          this.operation = response.data || {};
          if (this.operation.start_date) {
            this.operation.start_date = this.operation.start_date.split("T")[0];
          }
          if (this.operation.end_date) {
            this.operation.end_date = this.operation.end_date.split("T")[0];
          }
        } else {
          this.errorMessage = "Error al obtener los detalles de la operación";
        }
      } catch (error) {
        this.errorMessage = "Error al obtener los detalles de la operación: " + error.message;
      } finally {
        this.loading = false;
      }
    },
    async updateOperation() {
      this.isUpdating = true;
      const recomprasArray = this.recomprasInput
        ? this.recomprasInput.split(",").map((price) => parseFloat(price.trim()))
        : [];
      try {
        const response = await axios.put(
          `https://api.fortunia.org/api/operations/${this.operationId}`,
          {
            ...this.operation,
            recompras: recomprasArray,
          }
        );
        if (response.status === 200) {
          alert("Operación actualizada exitosamente");
          this.$router.push("/admin");
        } else {
          alert("Error al actualizar la operación");
        }
      } catch (error) {
        console.error("Error al actualizar la operación:", error);
        alert("Hubo un error al intentar actualizar la operación.");
      } finally {
        this.isUpdating = false;
      }
    },
    async deleteOperation() {
      if (!confirm("¿Estás seguro de que deseas eliminar esta operación?")) return;
      try {
        const response = await axios.delete(
          `https://api.fortunia.org/api/operations/${this.operationId}`
        );
        if (response.status === 200) {
          alert("Operación eliminada exitosamente");
          this.$router.push("/admin");
        } else {
          alert("Error al eliminar la operación");
        }
      } catch (error) {
        console.error("Error al eliminar la operación:", error);
        alert("Ocurrió un error al intentar eliminar la operación.");
      }
    },
  },
};
</script>


  
  
  <style scoped>
  .edit-operation-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .loading,
  .error {
    text-align: center;
    font-size: 1.2em;
  }
  
  .operation-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group.full-width {
    grid-column: span 2;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="number"],
  input[type="date"],
  textarea {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  .form-buttons {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 15px;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button.submit-btn {
    background-color: #4caf50;
    color: white;
  }
  
  button.submit-btn:hover {
    background-color: #45a049;
  }
  
  button.cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  button.cancel-btn:hover {
    background-color: #d32f2f;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 600px) {
    .operation-form {
      grid-template-columns: 1fr;
    }
  }


/* Botón Eliminar */
button.delete-btn {
  background-color: #f44336;
  color: white;
}

button.delete-btn:hover:not(:disabled) {
  background-color: #d32f2f;
}

  </style>