<!--views/AdminPanel.vue-->

<template>


  <!-- Alerta de transmisión en vivo -->
  <div v-if="isLiveStreamActive" class="live-stream-alert" @click="goToLiveStream">
    <p>¡Tenemos una clase en vivo en este momento! ¡No te la pierdas!</p>
  </div>
  
  
      <div class="admin-panel">
  
        <!-- Botón de Cerrar Sesión -->
        <div class="logout-container">
        <button @click="logout" class="logout-button">Cerrar Sesión</button>   
      </div> 
  
  
  
  
        <h2>Admin Panel</h2>
    
         <!-- Resumen de operaciones -->
      <div class="operations-summary">
        <h3>Resumen de Operaciones</h3>
        <div class="summary-card">
          <p>Operaciones Ganadas (Closed): <strong>{{ closedOrders.length }}</strong></p>
          <p>Operaciones Perdidas (Terminadas): <strong>{{ lostOrders.length }}</strong></p>
          <p>Operaciones Abiertas (Open): <strong>{{ openOrders.length }}</strong></p>
          <p>Promedio (Ganadas/Perdidas): 
            <strong>{{ calculateWinLossRatio }}</strong>
          </p>
        </div>
      </div>
  
      <!-- Menú de administración -->
      <div class="admin-menu">  
        <button @click="showUserManagement = !showUserManagement">Gestionar Usuarios</button>
      </div>



  <!-- Mostrar los usuarios en línea en la parte superior -->
<div class="online-users-summary">
  <h3>Usuarios en Línea</h3>
  <ul>
    <li v-for="user in onlineUsers" :key="user.user_id">
      <span>{{ user.username }}</span>
      <span class="last-active">Última actividad: {{ new Date(user.last_active).toLocaleString() }}</span>
    </li>
  </ul>
</div>



  
      <!-- Mostrar el componente de gestión de usuarios -->
      <UserManagement v-show="showUserManagement" />
       
  
       <!-- Contenedor de Pestañas -->
       <div class="tabs">
        <button @click="activeTab = 'open'" :class="{ active: activeTab === 'open' }">Operaciones Abiertas</button>
        <button @click="activeTab = 'closed'" :class="{ active: activeTab === 'closed' }">Operaciones Ganadas</button>
        <button @click="activeTab = 'lost'" :class="{ active: activeTab === 'lost' }">Operaciones Perdidas</button>
        <button @click="activeTab = 'contactForm'" :class="{ active: activeTab === 'contactForm' }">Formulario de Correo</button>
        <button @click="activeTab = 'economicCalendar'" :class="{ active: activeTab === 'economicCalendar' }">Calendario Económico</button>
        <button @click="activeTab = 'marketHolidays'" :class="{ active: activeTab === 'marketHolidays' }">Días Festivos de Mercado</button>
        <button @click="activeTab = 'classes'" :class="{ active: activeTab === 'classes' }">Clases Programadas</button>

       

  
     
      </div>
  
  <!-- Mostrar el formulario de contacto -->
  <div v-if="activeTab === 'contactForm'">
    <h3>Formulario de Correo y SMS</h3>
    <form @submit.prevent="handleSubmission">
      <div>
        <label for="subject">Asunto:</label>
        <input v-model="email.subject" type="text" id="subject" placeholder="Asunto del mensaje" />
      </div>
      <div>
        <label for="message">Mensaje:</label>
        <textarea v-model="email.message" id="message" required></textarea>
      </div>
      <div>
        <label>
          <input type="checkbox" v-model="email.isPremium" />
          Para Usuarios Premium
        </label>
      </div>
      <div>
        <label>
          <input type="checkbox" v-model="sendAsSMS" />
          Enviar como SMS
        </label>
      </div>
      <button type="submit" :disabled="isSubmitting">Enviar Mensaje</button>
    </form>
    <p v-if="submissionStatus">{{ submissionStatus }}</p>
  </div>
  
  
  
  
  <div v-if="activeTab === 'marketHolidays'" class="market-holidays">
    <h3>Días Festivos de Mercado</h3>
    <div v-if="marketHolidays.today.length === 0" class="no-holidays">
      <p>No hay días festivos hoy.</p>
    </div>
    <div v-if="marketHolidays.week.length > 0" class="week-holidays">
      <h4>Próximos días festivos esta semana:</h4>
      <ul>
        <li v-for="holiday in marketHolidays.week" :key="holiday.id" class="holiday-item">
          <span class="holiday-date">{{ formatDate(holiday.date) }}</span> -
          <span class="holiday-name">{{ holiday.name }}</span>
          <span v-if="holiday.status === 'early-close'" class="holiday-status early-close">(Cierre temprano: {{ holiday.close_time }})</span>
          <span v-if="holiday.status === 'closed'" class="holiday-status closed">(Mercado cerrado)</span>
        </li>
      </ul>
    </div>
    <div v-if="marketHolidays.month.length > 0" class="month-holidays">
      <h4>Días festivos este mes:</h4>
      <ul>
        <li v-for="holiday in marketHolidays.month" :key="holiday.id" class="holiday-item">
          <span class="holiday-date">{{ formatDate(holiday.date) }}</span> -
          <span class="holiday-name">{{ holiday.name }}</span>
          <span v-if="holiday.status === 'early-close'" class="holiday-status early-close">(Cierre temprano: {{ holiday.close_time }})</span>
          <span v-if="holiday.status === 'closed'" class="holiday-status closed">(Mercado cerrado)</span>
        </li>
      </ul>
    </div>
  </div>
  
  


  <div v-if="activeTab === 'economicCalendar'" class="economic-calendar">
  <h3>Calendario Económico</h3>
  <div v-if="economicEvents.today.length === 0" class="no-events">
    <p>No hay eventos económicos importantes hoy.</p>
  </div>
  <div v-if="economicEvents.today.length > 0" class="today-events">
    <h4>Eventos de Hoy:</h4>
    <ul>
      <li v-for="event in economicEvents.today" :key="event.id" class="event-item">
        <!-- Mostrar la fecha junto con el día de la semana -->
        <span class="event-date">{{ formatDate(event.event_date) }} - {{ event.day_of_week }}</span> -
        <span class="event-time">{{ formatTime(event.event_time) }}</span> -
        <span class="event-name">{{ event.event_name }}</span>
        <span class="event-country">({{ event.country }})</span>
      </li>
    </ul>
  </div>
  <div v-if="economicEvents.upcoming.length > 0" class="upcoming-events">
    <h4>Próximos eventos importantes:</h4>
    <ul>
      <li v-for="event in economicEvents.upcoming" :key="event.id" class="event-item">
        <!-- Mostrar la fecha junto con el día de la semana -->
        <span class="event-date">{{ formatDate(event.event_date) }} - {{ event.day_of_week }}</span> -
        <span class="event-time">{{ formatTime(event.event_time) }}</span> -
        <span class="event-name">{{ event.event_name }}</span>
        <span class="event-country">({{ event.country }})</span>
      </li>
    </ul>
  </div>
</div>






<div v-if="activeTab === 'classes'" class="classes-schedule">
  <h3>Horario de Clases</h3>

  <!-- Mensaje para cuando no hay clases -->
  <div v-if="todayClasses.length === 0 && upcomingClasses.length === 0" class="no-classes">
    <p>No hay clases programadas para esta semana.</p>
  </div>

  <!-- Clases de hoy -->
  <div v-else-if="todayClasses.length > 0" class="today-classes">
    <h4>Clases de Hoy:</h4>
    <div class="classes-container">
      <div v-for="classItem in todayClasses" :key="classItem.id" 
           :class="{'class-item': true, 'today': true, 'live': classItem.is_live}">
        <span class="class-day">{{ getDayOfWeek(classItem.class_date) }}</span> -
        <span class="class-title">{{ classItem.title }}</span>
        <span class="class-time">({{ formatTime(classItem.class_time) }})</span>
        <span class="class-level">- Nivel: {{ classItem.level }}</span>
      </div>
    </div>
  </div>

  <!-- Clases próximas -->
  <div v-if="upcomingClasses.length > 0" class="upcoming-classes">
    <h4>Próximas Clases:</h4>
    <div class="classes-container">
      <div v-for="classItem in upcomingClasses" :key="classItem.id" 
           :class="{'class-item': true, 'upcoming': true, 'live': classItem.is_live}">
        <span class="class-date">{{ formatDate(classItem.class_date) }} - {{ getDayOfWeek(classItem.class_date) }}</span> -
        <span class="class-title">{{ classItem.title }}</span>
        <span class="class-time">({{ formatTime(classItem.class_time) }})</span>
        <span class="class-level">- Nivel: {{ classItem.level }}</span>
      </div>
    </div>
  </div>
</div>






  
  
       
    <!-- Mostrar órdenes abiertas -->
   <div v-if="activeTab === 'open'">   
          
    <div v-if="openOrders.length === 0">No open orders available</div>
  
        
    <div v-if="openOrders.length > 0">
    <h3>Ordenes Abiertas</h3>
  
   <div class="order-cards">
    <div v-for="order in openOrders" :key="order.id" class="order-card">
      <h4>
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer"
          class="asset-link">
          {{ order.asset }}
        </a> 
        - <span class="order-status">{{ order.status }}</span>
      </h4>
      <p v-if="order.company_name" class="company-name">
        {{ order.company_name }}
      </p>

      <p 
         class="current-price" 
         @click="openAssetModal(order.asset)"
         style="cursor: pointer; text-decoration: underline;">
         Precio Actual: {{ order.current_price || 'Cargando...' }}
      </p>


      <p class="price-variation" v-if="order.current_price && order.target_price_1">
      <span 
        :class="['price-variation-item', { negative: (order.target_price_1 - order.current_price) > 0 }]">
        Variación hasta TP1: 
        {{ (order.target_price_1 - order.current_price).toFixed(2) }} 
        ({{ ((order.target_price_1 - order.current_price) / order.current_price * 100).toFixed(2) }}%)
      </span>
    </p>

    <p class="price-variation" v-if="order.current_price && order.entry_min">
      <span 
        :class="['price-variation-item', { negative: (order.current_price - order.entry_min) < 0 }]">
        Variación desde el Precio de Compra: 
        {{ (order.current_price - order.entry_min).toFixed(2) }} 
        ({{ ((order.current_price - order.entry_min) / order.entry_min * 100).toFixed(2) }}%)
      </span>
    </p>


      <div class="order-details">
        <p>Tipo de Orden: <span>{{ order.type }}</span></p>
        <p>Precio Minimo de Compra: <span>{{ order.entry_min }}</span></p>
        <p>Precio Maximo de Compra: <span>{{ order.entry_max }}</span></p>
       <!--  <p>Salir en Perdida: <span>{{ order.stop_loss }}</span></p>   -->
        <p>Precio Objetivo 1 : <span>{{ order.target_price_1 }}</span></p>
        <p>Precio Objetivo 2 : <span>{{ order.target_price_2 }}</span></p>
        <p>Fecha de Inicio: <span>{{ formatDate(order.start_date) }}</span></p>
      </div>




<!-- Mostrar recompras asociadas -->
<div v-if="order.recompras && order.recompras.length > 0">
  <h5>Recompras Asociadas:</h5>
  <ul class="recompras-list">
    <li v-for="recompra in order.recompras" :key="recompra.id" class="recompra-item">
      <div class="recompra-info">
        <span class="recompra-price">Precio: ${{ recompra.price }}</span>
        <span class="recompra-date">{{ formatDate(recompra.created_at) }}</span>
      </div>
    </li>
  </ul>
</div>



      <p v-if="order.comment" class="operation-notes">
        <strong>Notas de la Operación:</strong> {{ order.comment }}
      </p>






      <button @click="editOrder(order.id)" class="edit-button">Editar</button>
    </div>
   </div>
  
  </div>
  
  

  </div>





<!-- Modal para mostrar datos del activo -->
<div v-if="showAssetModal" class="modal">
  <div class="modal-content">
    <h3>Detalles del Activo</h3>
    <div class="modal-data">
      <p><strong>Precio Actual:</strong> {{ activeAssetData?.currentPrice }}</p>
      <p><strong>Precio Máximo del Día:</strong> {{ activeAssetData?.highPriceOfDay }}</p>
      <p><strong>Precio Mínimo del Día:</strong> {{ activeAssetData?.lowPriceOfDay }}</p>
      <p><strong>Precio de Apertura:</strong> {{ activeAssetData?.openPriceOfDay }}</p>
      <p><strong>Cierre Anterior:</strong> {{ activeAssetData?.previousClosePrice }}</p>
    </div>
    <button class="close-btn" @click="closeAssetModal">Cerrar</button>
  </div>
</div>

  
  
  
  
        <!-- Mostrar órdenes cerradas -->
  
        <div v-if="activeTab === 'closed'">
  
        <div v-if="closedOrders.length > 0">
          <h3>Ordenes Completadas con Exito</h3>
         
         
          <div class="order-cards">
            <div v-for="order in closedOrders" :key="order.id" class="order-card">
              <h4>
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer"
          class="asset-link">
          {{ order.asset }}
        </a> 
        - <span class="order-status">{{ order.status }}</span>
      </h4>
      <p v-if="order.company_name" class="company-name">
        {{ order.company_name }}
      </p>
      
      <div class="order-details">
        <p>Tipo de Orden: <span>{{ order.type }}</span></p>
        <p>Precio Minimo de Compra: <span>{{ order.entry_min }}</span></p>
        <p>Precio Maximo de Compra: <span>{{ order.entry_max }}</span></p>
       <!--  <p>Salir en Perdida: <span>{{ order.stop_loss }}</span></p>   -->
        <p>Precio Objetivo 1 : <span>{{ order.target_price_1 }}</span></p>
        <p>Precio Objetivo 2 : <span>{{ order.target_price_2 }}</span></p>
        <p>Fecha de Inicio: <span>{{ formatDate(order.start_date) }}</span></p>
        <p>Fecha de Venta: <span>{{ formatDate(order.end_date) }}</span></p>
      </div>
      <p v-if="order.comment" class="operation-notes">
        <strong>Notas de la Operación:</strong> {{ order.comment }}
      </p>
      <button @click="editOrder(order.id)" class="edit-button">Editar</button>
    </div>
  </div>
  
        </div>
     </div>
  
  
  
    <!-- Mostrar órdenes perdidas (terminadas) -->
  
    <div v-if="activeTab === 'lost'">   
  
  
      <div v-if="lostOrders.length > 0">
        <h3>Órdenes Perdidas</h3>
        <div class="order-cards">
          <div v-for="order in lostOrders" :key="order.id" class="order-card">
            <h4>
          <!-- Enlace al activo en Finviz -->
          <a 
            :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
            target="_blank" 
            rel="noopener noreferrer">
            {{ order.asset }}
          </a> 
          - {{ order.status }}
        </h4>
  
        <p v-if="order.company_name" class="company-name">
              {{ order.company_name }}
        </p>
  
  
            <p>Tipo de Orden: {{ order.type }}</p>
            <p>Precio Mínimo de Compra: {{ order.entry_min }}</p>
            <p>Precio Máximo de Compra: {{ order.entry_max }}</p>
            <p>Salir en Pérdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Inicio: {{ formatDate(order.end_date) }}</p>
            <p>Fecha de Perdida: {{ formatDate(order.start_date) }}</p>  
            <!-- Estilo para las notas -->
            <p v-if="order.comment" class="operation-notes">
                 <strong>Notas de la Operación:</strong> {{ order.comment }}
            </p>
          </div>
        </div>
      </div>
    </div>
  
    
      <!-- Botón para iniciar la creación de orden -->
      <button @click="startAddingOrder" class="create-new-order">Crear Nueva Orden</button>
  
    
        <!-- Formulario para agregar una orden -->
        <div v-if="isAddingOrder">
          <h3>Agregar una nueva Operacion</h3>
          <form @submit.prevent="submitNewOrder">
            <div>
              <label for="asset">Activo:</label>
              <input v-model="newOrder.asset" type="text" id="asset" required />
            </div>
            <div>
              <label for="type">Tipo:</label>
              <select v-model="newOrder.type" id="type" required>
                <option value="buy">Comprar</option>
                <option value="sell">Vender</option>
              </select>
            </div>
            <div>
              <label for="entry_min">Entrada Mínima:</label>
              <input v-model.number="newOrder.entry_min" type="number" step="0.01" id="entry_min" required />
            </div>
            <div>
              <label for="entry_max">Entrada Máxima:</label>
              <input v-model.number="newOrder.entry_max" type="number" step="0.01" id="entry_max" required />
            </div>
            <div>
              <label for="stop_loss">Stop Loss:</label>
              <input v-model.number="newOrder.stop_loss" type="number" step="0.01" id="stop_loss" />
            </div>
  
            <!-- 
            <div>
              <label for="target_price_1">Target Price 1:</label>
              <input v-model.number="newOrder.target_price_1" type="number" step="0.01" id="target_price_1" />
            </div>
            <div>
              <label for="target_price_2">Target Price 2:</label>
              <input v-model.number="newOrder.target_price_2" type="number" step="0.01" id="target_price_2" />
            </div>
  
             -->
  
  
            <div>
              <label for="status">Status:</label>
              <select v-model="newOrder.status" id="status" required>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                <option value="terminada">Perdida</option>
              </select>
            </div>
            
            <div>
              <label for="start_date">Start Date:</label>
              <input v-model="newOrder.start_date" type="date" id="start_date" required />
            </div>
            <div>
              <label for="comment">Comentarios de la operacion:</label>
              <textarea v-model="newOrder.comment" id="comment"></textarea>
            </div>
            <button type="submit" :disabled="isOrderSubmitting">Submit</button>
            <button type="button" @click="cancelAddingOrder">Cancel</button>
          </form>
        </div>
    
  
  
   
       <!-- Pie de página --> 
         
       <footer class="footer">
        <p>Fortunia 2024 Todos los Derechos Reservados</p>
        <p>Creado por <strong>Coss Consulting Group</strong></p>
        <nav>
          <ul>
            <li><router-link to="/privacy">Política de Privacidad</router-link></li>
            <li><router-link to="/disclaimer">Disclaimers</router-link></li>
            <li><router-link to="/about">Acerca de</router-link></li>
            <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
    
          </ul>
        </nav>
      </footer>
    
      
  
  
    </div>
  
    </template>
  
  <!--Programacion de las funciones-->
  
  
  <script>
  import axios from "axios";
  import UserManagement from '@/components/UserManagement.vue';
  
  export default {
    components: {
      UserManagement,
    },
    data() {
      return {
        socket: null,

        activeAssetData: null, // Información del activo seleccionado
        showAssetModal: false, // Controla la visibilidad del modal

        todayClasses: [],
        upcomingClasses: [],
      
        activeAsset: null, // Activo seleccionado
        isLiveStreamActive: false,
        liveStreamCheckInterval: null,
        openOrders: [],
        closedOrders: [],
        lostOrders: [],
        activeTab: 'open',
        marketHolidays: {
        today: [],
        week: [],
        month: [],
      },
      economicEvents: {
        today: [],
        upcoming: [],
      },
        email: {
          subject: 'Noticias de Mercado', // Asunto predeterminado
          message: '', // Mensaje vacío por defecto
          isPremium: false,
        },
        isSubmitting: false,
        sendAsSMS: false,
        emailStatus: '', // Para mostrar el estado del envío de correo
        submissionStatus: '',

        onlineUsersCheckInterval: null,       
        onlineUsers: [], // Inicializar la lista de usuarios en línea
        showCreateUserForm: false,
        showDeleteUserForm: false,
        showChangePasswordForm: false,
        showUserManagement: false,
        newUser: {
          name: '',
          email: '',
          password: '',
          role: 'user',
          phone_number: '', // Nuevo campo
          receive_sms_alerts: false, // Nuevo campo
        },
        deleteUserId: null,
        changePasswordId: null,
        newPassword: '',
        
        isAddingOrder: false, // Controla la visibilidad del formulario de creación de orden
        newOrder: {
          asset: '',
          type: 'buy',
          entry_min: null,
          entry_max: null,
          stop_loss: null,
          status: 'open',
          start_date: '',
          comment: '',
        },
        isOrderSubmitting: false, // Deshabilita el botón de submit mientras se procesa
      };
    },
  
    computed: {
      calculateWinLossRatio() {
        if (this.closedOrders.length === 0 && this.lostOrders.length === 0) {
          return "N/A";
        }
        return (this.closedOrders.length / (this.lostOrders.length || 1)).toFixed(2);
      },
    },
    created() {
      
      this.fetchOrders();
      this.fetchMarketHolidays(); 
      this.fetchEconomicEvents();
      this.checkLiveStreamStatus();  
      this.fetchClasses();
      
      
    },
  
    mounted() {
  
      /* 
      this.isLiveStreamActive = localStorage.getItem("isStreamingActive") === 'true';  // Obtenemos el valor del localStorage
      */
        
      this.startLiveStreamCheck();
      this.initializeWebSocket();
      this.startonlineUsersCheck();
  
     
  },
  
    beforeUnmount() {
      // Limpia el intervalo al destruir el componente
      if (this.liveStreamCheckInterval) {
        clearInterval(this.liveStreamCheckInterval);
      }
  
  
    if (this.socket) {
      this.socket.close();  // Cierra la conexión WebSocket
    }

    // Limpia el intervalo al destruir el componente
    if (this.onlineUsersCheckInterval) {
     clearInterval(this.onlineUsersCheckInterval);
   }

  
    },
  
  
  
    methods: {

      initializeWebSocket() {
      this.socket = new WebSocket("wss://socket.fortunia.org");

      this.socket.onopen = () => {
        console.log("Conectado al WebSocket");

        // Suscribirse al activo activo si existe
        const activeAsset = this.getActiveAsset();
        if (activeAsset) {
          this.subscribeToAsset(activeAsset);
        }
      };

      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (Array.isArray(data)) {
          // Si se recibe un conjunto de actualizaciones
          data.forEach(({ symbol, price }) => this.updateOrderPrice(symbol, price));
        } else {
          const { symbol, price } = data;
          if (symbol && price) {
            this.updateOrderPrice(symbol, price);
          } else {
            console.warn("Datos incompletos recibidos del WebSocket:", data);
          }
        }
      };

      this.socket.onerror = (error) => {
        console.error("Error en el WebSocket:", error);
      };

      this.socket.onclose = () => {
        console.log("Conexión WebSocket cerrada");
      };
    },

    // Enviar una suscripción para recibir actualizaciones de un activo
    subscribeToAsset(asset) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ action: "subscribe", asset }));
        console.log("Suscrito al activo:", asset);
      }
    },

    // Obtener el activo activo de la pestaña
    getActiveAsset() {
      // Verifica si existen órdenes abiertas
      if (this.openOrders && this.openOrders.length > 0) {
        // Busca el activo de la primera orden abierta con un activo definido
        const activeOrder = this.openOrders.find((order) => order.asset);
        // Si se encuentra una orden válida con un activo
        if (activeOrder && activeOrder.asset) {
          this.activeAsset = activeOrder.asset; // Guardar el activo activo
          return activeOrder.asset;
        }
      }
      // Si no se encuentra un activo, devuelve null o el valor que desees
      return null;
    },

    // Comprobar si el activo es el que está actualmente abierto
    isActiveAsset(asset) {
      console.log("Comparando el activo:", asset, "con el activo de la pestaña:", this.activeAsset);
      return asset === this.activeAsset; // Comparación directa de activos
    },

    // Actualizar el precio de la orden correspondiente
    updateOrderPrice(symbol, price) {
      console.log("Actualizando precio para el activo:", symbol, "con precio:", price);

      // Encuentra y actualiza la orden que coincide con el símbolo recibido
      const orderToUpdate = this.openOrders.find((order) => order.asset === symbol);
      if (orderToUpdate) {
        orderToUpdate.current_price = price; // Actualiza el precio
        console.log("Precio actualizado para la orden:", orderToUpdate);
      } else {
        console.log("No se encontró ninguna orden abierta con el símbolo:", symbol);
      }
    },

    
    //Fin de socket
  
  
  
  
  
      async handleSubmission() {
        if (!this.email.message.trim()) {
          this.submissionStatus = "El mensaje no puede estar vacío.";
          return;
        }
  
        this.isSubmitting = true;
        this.submissionStatus = '';
  
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No estás autenticado.');
          }
  
          if (this.sendAsSMS) {
            await this.sendSMS(token);
          } else {
            await this.sendEmail(token);
          }
  
          this.submissionStatus = "Mensaje enviado exitosamente.";
          this.email.message = '';
          this.email.subject = '';
          this.sendAsSMS = false;
        } catch (error) {
          this.submissionStatus = error.message || "Hubo un problema al enviar el mensaje.";
        } finally {
          this.isSubmitting = false;
        }
      },
  
      async sendEmail(token) {
        const audience = this.email.isPremium ? 'premium' : 'all';
        await axios.post('https://api.fortunia.org/api/users/send-bulk-email', {
          subject: this.email.subject,
          message: this.email.message,
          audience,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      },
  
      async sendSMS(token) {
        await axios.post('https://api.fortunia.org/api/sms/send-sms', {
          message: this.email.message,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      },
  
  
  
      async fetchMarketHolidays() {
      try {
        const todayResponse = await fetch('https://api.fortunia.org/api/market-holidays/today');
        const weekResponse = await fetch('https://api.fortunia.org/api/market-holidays/week');
        const monthResponse = await fetch('https://api.fortunia.org/api/market-holidays/month');
  
        const todayData = await todayResponse.json();
        const weekData = await weekResponse.json();
        const monthData = await monthResponse.json();
  
        if (todayData.success) this.marketHolidays.today = todayData.holidays;
        if (weekData.success) this.marketHolidays.week = weekData.holidays;
        if (monthData.success) this.marketHolidays.month = monthData.holidays;
      } catch (error) {
        console.error('Error al cargar los días festivos:', error);
      }
    },
  
  
  
  
      async fetchOrders() {
          try {
            const response = await axios.get("https://api.fortunia.org/api/operations", {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
  
            
  
            if (response.data && Array.isArray(response.data)) {
              // Ordenar órdenes abiertas por fecha de inicio (de más nuevas a más antiguas)
             
              this.openOrders = response.data
                .filter((order) => order.status === "open")
                .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
  
              // Ordenar órdenes cerradas según el criterio
              this.closedOrders = response.data
                .filter((order) => order.status === "closed")
                .sort((a, b) => {
                  const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
                  if (endDateDiff !== 0) return endDateDiff; // Primero por `end_date`
                  return new Date(b.start_date) - new Date(a.start_date); // Luego por `start_date`
                });
            }
  
            // Obtener y ordenar las órdenes terminadas (perdidas)
            const lostResponse = await axios.get("https://api.fortunia.org/api/operations/terminada", {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
  
            if (lostResponse.data && Array.isArray(lostResponse.data)) {
              // Ordenar órdenes terminadas según el mismo criterio que las cerradas
              this.lostOrders = lostResponse.data.sort((a, b) => {
                const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
                if (endDateDiff !== 0) return endDateDiff; // Primero por `end_date`
                return new Date(b.start_date) - new Date(a.start_date); // Luego por `start_date`
              });
            }
  
          } catch (error) {
            console.error("Error fetching orders", error);
            alert("Unable to fetch orders. Please try again later.");
          }
        },
  
      startAddingOrder() {
        this.isAddingOrder = true;
      },
      cancelAddingOrder() {
        this.isAddingOrder = false;
        this.resetNewOrder();
      },
      resetNewOrder() {
        this.newOrder = {
          asset: "",
          type: "buy",
          entry_min: null,
          entry_max: null,
          stop_loss: null,       
          status: "open",
          start_date: "",
          comment: "",
        };
      },
  
   
      async submitNewOrder() {
    this.isOrderSubmitting = true; // Deshabilitar el botón de submit mientras se procesa
  
    // Verificar si los campos necesarios están completos
    if (!this.newOrder.asset || !this.newOrder.entry_min || !this.newOrder.entry_max || !this.newOrder.start_date) {
      alert("Por favor, complete todos los campos requeridos.");
      this.isOrderSubmitting = false;
      return;
    }
  
    try {
      // Enviar la solicitud POST para crear la nueva orden
      const response = await axios.post("https://api.fortunia.org/api/operations", this.newOrder, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
  
      // Verificar si la respuesta es válida y agregar la nueva orden
      if (response.data) {
        this.openOrders.push(response.data); // Añadir la nueva orden a la lista
        this.cancelAddingOrder(); // Cerrar el formulario después de enviar
        alert("Orden creada exitosamente");
      }
    } catch (error) {
      console.error("Error creando la nueva orden:", error);
      alert("Error al crear la orden. Intenta de nuevo más tarde.");
    } finally {
      this.isOrderSubmitting = false; // Rehabilitar el botón de submit después de procesar
    }
  },
  
  
  
  
  
  
  
  
  
      editOrder(id) {
        this.$router.push(`/edit-order/${id}`);
      },
      formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString('en-US', options);
      },
  
  
     // Métodos para la administración de usuarios
  async createUser() {
    try {
      await axios.post('https://api.fortunia.org/api/users', this.newUser, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      alert('Usuario creado exitosamente');
      this.showCreateUserForm = false;
      this.fetchUsers();
    } catch (error) {
      console.error("Error creating user", error);
      alert('Error al crear usuario');
    }
  },
  
  async deleteUser() {
    try {
      await axios.delete(`https://api.fortunia.org/api/users/${this.deleteUserId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      alert('Usuario eliminado exitosamente');
      this.showDeleteUserForm = false;
      this.fetchUsers();
    } catch (error) {
      console.error("Error deleting user", error);
      alert('Error al eliminar usuario');
    }
  },
  
  async changePassword() {
    try {
      await axios.put(
        `https://api.fortunia.org/api/users/${this.changePasswordId}/password`,
        { password: this.newPassword },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert('Contraseña cambiada exitosamente');
      this.showChangePasswordForm = false;
    } catch (error) {
      console.error('Error cambiando la contraseña', error);
      alert('Error al cambiar contraseña');
    }
  },
  
  logout() {
    axios
      .post("https://api.fortunia.org/api/auth/logout")
      .then(() => {
        // Limpia el localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("role");
  
        // Redirige al usuario a la página de inicio
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error al cerrar sesión:", error);
  
        // Asegúrate de limpiar y redirigir incluso si hay un error
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        window.location.href = "/";
      });
  },
  
  
   // Método para verificar el estado de la transmisión
   async checkLiveStreamStatus() {
    try {
      const authToken = localStorage.getItem('token');
      if (!authToken) {
        console.error("Token de autenticación no encontrado");
        return;
      }
  
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      };
  
      const response = await axios.get("https://api.fortunia.org/api/owncast/status", config);
  
      // Depuración para ver la respuesta de la API
      console.log("Respuesta de la API:", response.data);
  
      const isCurrentlyLive = response.data.status === "En línea"; // Verificar si está en línea
      localStorage.setItem("isStreamingActive", isCurrentlyLive); // Guardar estado en localStorage
  
      if (isCurrentlyLive !== this.isLiveStreamActive) {
        this.isLiveStreamActive = isCurrentlyLive;
  
        // Alerta si la transmisión está activa
        if (isCurrentlyLive) {
          this.showLiveStreamAlert();
        }
      }
    } catch (error) {
      console.error("Error al verificar el estado de la transmisión:", error);
    }
  },
  
  
   
  
       // Método para iniciar la verificación periódica
       startLiveStreamCheck() {
        // Limpia cualquier intervalo existente
        if (this.liveStreamCheckInterval) {
          clearInterval(this.liveStreamCheckInterval);
        }
  
        // Configura una verificación periódica cada 5 segundos (5000 ms)
        this.liveStreamCheckInterval = setInterval(() => {
          this.checkLiveStreamStatus();
        }, 5000);
      },
  
  
      showLiveStreamAlert() {
        setTimeout(() => {
         // alert("¡Tenemos una clase en vivo en este momento! ¡No te la pierdas!");
        }, 1000);
      },
  
      // Navega a la página de transmisiones
      goToLiveStream() {
        window.location.href = "/transmissions";
      },



      async fetchEconomicEvents() {
    try {
        // Solicitar los eventos económicos del día actual y los próximos
        const response = await fetch('https://api.fortunia.org/api/calendar/economic-calendar');
        
        const data = await response.json();

        // Verificar que la respuesta sea exitosa
        if (data.success) {
            // Asignar los eventos del día y los próximos a las variables correspondientes
            this.economicEvents.today = data.today;
            this.economicEvents.upcoming = data.upcoming;
        } else {
            console.error('Error en la respuesta: ', data.error);
        }
    } catch (error) {
        console.error('Error al cargar los eventos económicos:', error);
    }
},


formatTime(time) {
    const [hour, minute] = time.split(':');
    return `${hour}:${minute}`;
},



// Obtener clases desde el endpoint
async fetchClasses() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get("https://api.fortunia.org/api/clases/classes-schedule", {
      headers: {
        "Authorization": `Bearer ${token}`  
      }
    });
    
    if (response.data.success) {
      const { today, upcoming } = response.data;
      this.todayClasses = today;
      this.upcomingClasses = upcoming;
    }
  } catch (error) {
    console.error("Error al obtener las clases:", error);
  }
},


getDayOfWeek(date) {
    const daysOfWeek = [
      'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ];
    const dayIndex = new Date(date).getDay();
    return daysOfWeek[dayIndex];
  },


  //Busco los usuarios   
  async fetchOnlineUsers() {
  const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
          "https://api.fortunia.org/api/users/online-users",
          {
            headers: {
              Authorization: `Bearer ${token}`,    
            },
          }
        );
        this.onlineUsers = response.data;
      } catch (error) {
        console.error("Error al obtener usuarios en línea:", error);
        this.onlineUsers = [];
      }
    },


    // Método para iniciar la verificación periódica
   startonlineUsersCheck() {
    // Limpia cualquier intervalo existente
     if (this.onlineUsersCheckInterval) {
          clearInterval(this.onlineUsersCheckInterval);
        }
  
        // Configura una verificación periódica cada 5 segundos (5000 ms)
        this.onlineUsersCheckInterval = setInterval(() => {
          this.fetchOnlineUsers();
        }, 5000);
      },





 // Método para abrir el modal del activo
 openAssetModal(asset) {
    this.activeAsset = asset; // Guarda el activo seleccionado
    this.showAssetModal = true; // Muestra el modal

    // Realiza la solicitud para obtener los detalles del activo
    axios.get(`https://api.fortunia.org/api/assets/quote?symbol=${asset}`)
      .then((response) => {
        this.activeAssetData = response.data; // Almacena los datos del activo
      })
      .catch((error) => {
        console.error("Error al cargar los detalles del activo:", error);
        this.activeAssetData = null; // Limpia los datos si ocurre un error
      });
  },

  // Método para cerrar el modal
  closeAssetModal() {
    this.showAssetModal = false;
    this.activeAssetData = null; // Limpia los datos del modal
  },


  
    },
  
    watch: {
      // Si el usuario inicia sesión o se actualiza el token
      'localStorage.token': function() {
        this.checkLiveStreamStatus(); // Verifica el estado de la transmisión al cambiar el token
      }
    },
  
  
  };
  </script>
  
  
  
    
    <style scoped>
   
  
   /* RESET DE ESTILOS GENERALES */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* FUENTES Y COLORES GENERALES */
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f7fc;
    color: #333;
    line-height: 1.6;
  }
  
  /* TÍTULOS Y ENCABEZADOS */
  h2, h3, h4 {
    color: #2a3d66;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 2rem;
    text-align: center;
    color: #1e2a47;
  }
  
  h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  /* CONTENEDOR PRINCIPAL */
  .admin-panel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* BOTÓN DE CIERRE DE SESIÓN */
  .logout-container {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .logout-button {
    background-color: #f64c72;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  
  .logout-button:hover {
    background-color: #e0385c;
  }
  
  /* RESUMEN DE OPERACIONES */
  .operations-summary {
    background-color: #eef2f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .summary-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .summary-card p {
    margin: 10px 0;
  }
  
  .summary-card strong {
    font-weight: 700;
  }
  
  /* MENÚ DE ADMINISTRACIÓN */
  .admin-menu {
    margin-top: 30px;
    text-align: center;
  }
  
  .admin-menu button {
    background-color: #42b983;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin: 10px;
    transition: background-color 0.3s ease;
  }
  
  .admin-menu button:hover {
    background-color: #36a277;
  }
  
  .admin-menu button.active {
    background-color: #2d8c6b;
  }
  
  /* FORMULARIOS Y ENTRADAS */
  form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  form div {
    margin-bottom: 15px;
  }
  
  form label {
    font-size: 1rem;
    color: #555;
  }
  
  /* Formulario de correo */
  form div {
    margin-bottom: 15px;
  }
  
  form input,
  form select,
  form textarea {
    width: 100%;
    padding: 12px; /* Aumentamos el padding para hacerlo más cómodo */
    margin-top: 5px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  form textarea {
    height: 200px; /* Aumentamos la altura del textarea */
    resize: vertical; /* Permite cambiar el tamaño verticalmente */
  }
  
  form textarea:focus {
    border-color: #42b983; /* Agrega un borde verde cuando el textarea está en foco */
    outline: none; /* Elimina el contorno por defecto */
  }
  
  form input[type="checkbox"] {
    width: auto;
  }
  
  form button {
    background-color: #42b983;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  form button:disabled {
    background-color: #ddd;
  }
  
  form button:hover {
    background-color: #36a277;
  }
  
  
  /* PESTAÑAS DE TABS */
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tabs button {
    background-color: #ffffff;
    color: #333;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .tabs button.active {
    background-color: #42b983;
    color: #fff;
    border-color: #36a277;
  }
  
  .tabs button:hover {
    background-color: #f4f7fc;
  }
  
  
  
  
  /* Pie de Página */
  .footer {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--secondary-color);
    text-align: center;
    border-top: 1px solid #ddd;
    font-size: 14px;
    color: #030303;
  }
  
  .footer p {
    margin: 5px 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .footer nav ul li {
    margin: 5px 0;
  }
  
  .footer nav ul li a {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: bold;
    transition: color 0.3s ease;
    font-size: 14px;
  }
  
  .footer nav ul li a:hover {
    color: #000;
  }
  
  /* Estilos específicos para pantallas pequeñas */
  @media (max-width: 768px) {
    .footer {
      font-size: 12px;
      padding: 10px;
    }
  
    .footer nav ul {
      flex-direction: column;
      gap: 10px;
    }
  
    .footer nav ul li a {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      font-size: 10px;
      padding: 8px;
    }
  
    .footer nav ul {
      flex-direction: column;
      gap: 5px;
    }
  
    .footer nav ul li a {
      font-size: 10px;
    }
  }
  
  /* Estilo adicional para mejorar la visualización */
  .footer p strong {
    font-weight: bold;
  }
  
  
  
  
  
  
  
  /* ESTILOS RESPONSIVOS */
  @media (max-width: 768px) {
    .order-cards {
      grid-template-columns: 1fr;
    }
  
    .tabs {
      flex-direction: column;
    }
  
    .tabs button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .admin-panel {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    h2 {
      font-size: 1.5rem;
    }
  
    .admin-panel {
      margin: 10px;
      padding: 10px;
    }
  }
  
  
  /* Botón "Crear Nueva Orden" */
  button.create-order {
    background-color: #42b983;
    color: #fff;
    padding: 12px 30px;
    font-size: 1.1rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
    display: inline-block;
    text-align: center;
  }
  
  button.create-order:hover {
    background-color: #36a277;
    transform: scale(1.05);
  }
  
  button.create-order:focus {
    outline: none;
  }
  
  /* Estilo para el formulario */
  form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  /* Botones de "Submit" y "Cancel" */
  form button[type="submit"],
  form button[type="button"] {
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-right: 15px;
  }
  
  form button[type="submit"] {
    background-color: #42b983;
    color: white;
  }
  
  form button[type="submit"]:hover {
    background-color: #36a277;
    transform: scale(1.05);
  }
  
  form button[type="button"] {
    background-color: #f64c72;
    color: white;
  }
  
  form button[type="button"]:hover {
    background-color: #e0385c;
    transform: scale(1.05);
  }
  
  form button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  /* Separación entre los botones */
  form .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  form .button-container button {
    margin-left: 10px;
  }
  
  
  
  /* Botón "Crear Nueva Orden" */
  button.create-new-order {
    background-color: #42b983; /* Color verde */
    color: #fff;
    padding: 12px 30px;
    font-size: 1.1rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
    margin-top: 10px; /* Separación en la parte superior */
    display: inline-block;
    text-align: center;
    margin-right: 15px; /* Añadido para separarlo un poco de otros elementos si es necesario */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para un efecto de profundidad */
  }
  
  button.create-new-order:hover {
    background-color: #36a277; /* Efecto hover más oscuro */
    transform: scale(1.05); /* Efecto de agrandado al pasar el cursor */
  }
  
  button.create-new-order:focus {
    outline: none; /* Elimina el borde de enfoque por defecto */
  }
  
  button.create-new-order:active {
    background-color: #2d8c6b; /* Efecto de presionado */
    transform: scale(1.02);
  }
  
  /* Resalta el estado activo */
  button.create-new-order.active {
    background-color: #36a277;
  }
  
  
  /*Festivos*/
  /* Contenedor principal */
  .market-holidays {
    font-family: 'Arial', sans-serif;
    color: #333;
    max-width: 800px;
    margin: 20px auto; /* Centra el contenedor en la página */
    padding: 20px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Títulos */
  .market-holidays h3 {
    font-size: 26px;
    color: #4CAF50;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .market-holidays h4 {
    font-size: 22px;
    color: #2196F3;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  /* Mensaje cuando no hay festivos */
  .market-holidays .no-holidays p {
    font-size: 18px;
    color: #757575;
    text-align: center;
  }
  
  /* Listas */
  .market-holidays ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Elementos individuales */
  .market-holidays .holiday-item {
    background: #ffffff;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .market-holidays .holiday-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Fechas */
  .market-holidays .holiday-date {
    font-weight: bold;
    color: #FF5722;
  }
  
  /* Nombres */
  .market-holidays .holiday-name {
    font-size: 16px;
    color: #333;
  }
  
  /* Estados */
  .market-holidays .holiday-status {
    font-size: 14px;
    font-style: italic;
  }
  
  .market-holidays .holiday-status.early-close {
    color: #FFC107;
  }
  
  .market-holidays .holiday-status.closed {
    color: #F44336;
  }
  
  /* Responsividad */
  @media (min-width: 768px) {
    .market-holidays .holiday-item {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  /* Clases en vivo */
  
  .live-stream-alert {
    background-color: #ffcc00;
    color: #000;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  

  /* Contenedor de las tarjetas */
  .order-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Ajusta el tamaño mínimo de las tarjetas */
    gap: 20px; /* Espacio entre tarjetas */
    margin: 20px 0; /* Separación vertical del contenedor */
  }
  
  /* Tarjetas individuales */
  .order-card {
    background-color: #ffffff; /* Fondo blanco limpio */
    border: 1px solid #e6e6e6; /* Borde gris claro */
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    font-family: 'Arial', sans-serif;
  }
  
  /* Títulos y enlaces */
  .asset-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .asset-link:hover {
    text-decoration: underline;
  }
  
  .order-status {
    font-size: 1em;
    font-weight: bold;
    color: #28a745; /* Verde para indicar estado positivo */
  }
  
  /* Detalles de la orden */
  .order-details p {
    font-size: 1em;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-details p span {
    font-weight: bold;
    color: #333;
  }
  
  .order-type span {
    color: #17a2b8; /* Azul claro */
  }
  
  .order-entry span {
    color: #ff9800; /* Naranja */
  }
  
  .order-stop-loss span {
    color: #dc3545; /* Rojo */
  }
  
  .order-target span {
    color: #28a745; /* Verde */
  }
  
  .order-start-date span {
    color: #6c757d; /* Gris */
  }
  
  /* Botón de edición */
  .edit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  /* Precio actual */
  .current-price {
    font-size: 1.2em;
    font-weight: bold;
    color: #2e0ee6;
    background-color: #f8f8f8;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }
  
  /* Notas de la operación */
  .operation-notes {
    background-color: #f0f8ff;
    padding: 10px;
    border-left: 5px solid #007bff;
    font-size: 1.1em;
    color: #333;
    border-radius: 5px;
    margin-top: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .operation-notes strong {
    color: #007bff;
  }
  
  

/*Calendario Economico */

/* Calendario Económico - Estilo moderno y responsivo */

.economic-calendar {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.economic-calendar h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.no-events,
.today-events,
.upcoming-events {
  margin: 15px 0;
}

.no-events p {
  font-size: 18px;
  color: #888;
  text-align: center;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2d3e50;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}

.event-item {
  background-color: #fff;
  padding: 10px;
  margin: 8px 0;
  border-radius: 8px;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.event-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-time,
.event-name,
.event-country,
.event-date {
  display: block;
  font-size: 14px;
  color: #555;
}

.event-time {
  font-weight: 600;
  color: #007bff;
}

.event-name {
  font-weight: 500;
  color: #333;
  margin-top: 5px;
}

.event-country {
  font-style: italic;
  color: #777;
  font-size: 12px;
}

.event-date {
  font-weight: 600;
  color: #5d6d7e;
  font-size: 15px;
}

/* Responsividad */
@media (max-width: 768px) {
  .economic-calendar {
    margin: 10px;
    padding: 15px;
  }

  .event-item {
    font-size: 13px;
    padding: 8px;
  }

  .event-time,
  .event-name,
  .event-country,
  .event-date {
    font-size: 13px;
  }

  h4 {
    font-size: 18px;
  }

  .economic-calendar h3 {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .economic-calendar {
    margin: 5px;
    padding: 10px;
  }

  .event-item {
    font-size: 12px;
    padding: 6px;
  }

  .event-time,
  .event-name,
  .event-country,
  .event-date {
    font-size: 12px;
  }

  h4 {
    font-size: 16px;
  }

  .economic-calendar h3 {
    font-size: 20px;
  }
}


/* Variaciones de Precios */
.price-variation {
  font-size: 1em;
  margin-top: 10px;
}

.price-variation-item {
  font-weight: bold;
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.price-variation-item.negative {
  color: red; /* Valores negativos */
}

.price-variation-item:not(.negative) {
  color: green; /* Valores positivos */
}

  
/* Estilo para las clases */

/* Estilo general */
.classes-schedule {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
  overflow: hidden;
}

h3 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

h4 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 15px;
}

.no-classes p {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
}

/* Contenedor de las clases */
.classes-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.class-item {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.class-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.class-item .class-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.class-item .class-time,
.class-item .class-level,
.class-item .class-date {
  font-size: 1rem;
  color: #777;
}

/* Clases en vivo */
.live {
  background-color: #ffeb3b;  /* Un color brillante y llamativo */
  border-left: 5px solid #fbc02d;  /* Un color dorado para destacar */
}

.live .class-title {
  color: #c2185b;  /* Un color vibrante para el título */
}

/* Clases del día */
.today {
  background-color: #eaf9e6;
  border-left: 5px solid #4caf50;
}

.today .class-title {
  color: #388e3c;
}

/* Clases próximas */
.upcoming {
  background-color: #e0f7fa;
  border-left: 5px solid #00bcd4;
}

.upcoming .class-title {
  color: #0097a7;
}


/* Respuesta a tamaños pequeños de pantalla */
@media (max-width: 768px) {
  .classes-schedule {
    padding: 15px;
  }

  .class-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .class-item .class-title {
    font-size: 1rem;
  }

  .class-item .class-time,
  .class-item .class-level,
  .class-item .class-date {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .classes-schedule {
    max-width: 100%;
    padding: 10px;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  .class-item {
    padding: 12px;
  }
}



/* Estilo para las recompras asociadas */
.recompras-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.recompra-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.recompra-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.recompra-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.recompra-price {
  font-weight: 600;
  color: #42b983;
  font-size: 1rem;
}

.recompra-date {
  color: #999;
  font-size: 0.9rem;
  text-align: right;
}

.recompras-list h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #2a3d66;
  font-weight: 600;
}


/* Sumario de Usuarios en Linea*/

/* Estilo para la sección de usuarios en línea */
.online-users-summary {
  background-color: #ffffff; /* Fondo blanco para resaltar */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.online-users-summary h3 {
  font-size: 1.5rem;
  color: #42b983; /* Color verde para el título */
  margin-bottom: 15px;
  text-align: center;
}

.online-users-summary ul {
  list-style: none;
  padding: 0;
}

.online-users-summary li {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f4f7fc; /* Fondo suave para cada elemento */
  font-size: 1rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease; /* Transición suave */
}

.online-users-summary li:hover {
  background-color: #e0f7f3; /* Color de fondo al pasar el mouse */
}

.online-users-summary li .last-active {
  font-size: 0.9rem;
  color: #888;
  font-style: italic;
}



/* Ventana modal con diseño estilizado */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: left;
  animation: scale-in 0.3s ease-in-out;
}

.modal-content h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.modal-data p {
  font-size: 1rem;
  color: #555;
  margin: 8px 0;
}

.modal-data p strong {
  color: #222;
}

.close-btn {
  display: inline-block;
  padding: 10px 20px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.close-btn:hover {
  background-color: #0056b3;
}

/* Animaciones */
@keyframes scale-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsividad */
@media (max-width: 480px) {
  .modal-content {
    padding: 15px;
    font-size: 0.9rem;
  }

  .modal-content h3 {
    font-size: 1.2rem;
  }

  .close-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}



  </style>