<template>
  <div class="login-container">
    <!-- Logo -->
    <img src="@/assets/logo.png" alt="Logo" class="login-logo" />

   
    <!-- Formulario de login -->
    <form v-if="showLogin" @submit.prevent="handleLogin" class="login-form">
      <input v-model="username" type="text" placeholder="Usuario ó Correo Electrónico" required />
      <input v-model="password" type="password" placeholder="Contraseña" required />
      <button type="submit">Login</button>
      <p class="forgot-password">
        <a @click="goToPasswordReset">¿Olvidaste tu contraseña?</a>
      </p>
    </form>

      <!-- Alternar entre Login y Registro -->
    <div class="toggle-forms">
      <a @click="showLogin = true" :class="{ active: showLogin }" class="toggle-link">Iniciar sesión</a>
      <span>|</span>
      <a @click="showLogin = false" :class="{ active: !showLogin }" class="toggle-link">Registrarse</a>
    </div>


    <!-- Componente de registro -->
    <Register v-if="!showLogin" />

    <!-- Mensajes -->
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    <p v-if="isLoggedIn" class="success">Estás logueado. Redirigiendo...</p>

   
     <!-- Pie de página -->
     <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>


  </div>
</template>

<script>
import axios from "axios";
import Register from "@/components/UserRegister.vue";

export default {
  components: { Register },
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      sessionActiveMessage: "",
      isLoggedIn: false,
      showLogin: true,
      isStreamingActive: false,
    };
  },

  created() {
    const token = localStorage.getItem("token");
    this.isLoggedIn = !!token; // Comprueba si hay un token
    if (token) {
      this.verifyToken(token); // Verifica el token al cargar la página
      
    }   
  },

  methods: {
    async handleLogin() {
    this.errorMessage = "";
    this.sessionActiveMessage = "";
    try {
      const sessionToken = localStorage.getItem("session_token");

      // Realiza la solicitud de login al backend
      const response = await axios.post(
        "https://api.fortunia.org/api/auth/login",
        {
          username: this.username,
          password: this.password,
          session_token: sessionToken,  // Envío del session_token actual
          invalidateSession: false  // Indicar que no se desea invalidar la sesión aún
        }
      );

      const { token, role, isStreamingActive } = response.data;

      // Guarda el token y el rol en localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      localStorage.setItem("session_token", token);  // Guarda el nuevo session_token
      localStorage.setItem("isStreamingActive", isStreamingActive);

      // Marca como logueado y redirige
      this.isLoggedIn = true;
      this.redirectUser(role);

    } catch (error) {
      const message = error.response?.data?.error || "Error en el login";

      // Si el backend indica que se requiere invalidar la sesión
      if (error.response?.data?.actionRequired === "invalidate-session") {
        const confirmInvalidate = confirm(
          "Ya tienes una sesión activa en otro dispositivo. ¿Deseas cerrar esa sesión y continuar con esta?"
        );
        
        if (confirmInvalidate) {
          // Envía la solicitud de login con el parámetro `invalidateSession: true` para invalidar la sesión anterior
          await this.handleInvalidateSession();
        }
      } else {
        this.errorMessage = message;
      }
    }
  },



    async verifyToken(token) {
      try {
        const response = await axios.get(
          "https://api.fortunia.org/api/auth/verify-token",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.isValid) {
          const role = localStorage.getItem("role");
          this.redirectUser(role);
        } else {
          this.clearSession();
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        this.clearSession();
      }
    },

    async handleInvalidateSession() {
    // Envía la solicitud para invalidar la sesión activa en otro dispositivo
    try {
      const sessionToken = localStorage.getItem("session_token");

      const response = await axios.post(
        "https://api.fortunia.org/api/auth/login",
        {
          username: this.username,
          password: this.password,
          session_token: sessionToken,  // Envío del session_token actual
          invalidateSession: true  // Indicar que se desea invalidar la sesión
        }
      );

      const { token, role } = response.data;

      // Guarda el nuevo token y rol
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      localStorage.setItem("session_token", token);  // Guarda el nuevo session_token

      this.isLoggedIn = true;
      this.redirectUser(role);

    } catch (error) {
      this.errorMessage = error.response?.data?.error || "Error al invalidar la sesión";
    }
  },

    redirectUser(role) {
      if (role === "admin") {
        this.$router.push("/admin");
      } else {
        this.$router.push("/home");
      }
    },

    clearSession() {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      this.isLoggedIn = false;
    },

    goToPasswordReset() {
      this.$router.push("/forgot-password");
    },

  },
};
</script>

<style scoped>
/* Estilos básicos para el login */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  margin-bottom: 15px;
  padding: 10px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 12px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 250px;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 15px;
}

.warning {
  color: orange;
  margin-top: 15px;
}

.success {
  color: green;
  margin-top: 15px;
}



/* Pie de Página */
.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #030303;
}

.footer p {
  margin: 5px 0;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.footer nav ul li {
  margin: 5px 0;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 14px;
}

.footer nav ul li a:hover {
  color: #000;
}

/* Estilos específicos para pantallas pequeñas */
@media (max-width: 768px) {
  .footer {
    font-size: 12px;
    padding: 10px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer nav ul li a {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
    padding: 8px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 5px;
  }

  .footer nav ul li a {
    font-size: 10px;
  }
}

/* Estilo adicional para mejorar la visualización */
.footer p strong {
  font-weight: bold;
}




/*Olvidar la Contrasena*/

.forgot-password {
  margin-top: 10px;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}



/* Nuevos estilos para enlaces */
.toggle-forms {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-forms a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
}

.toggle-forms a:hover {
  text-decoration: underline;
}

.toggle-forms a.active {
  color: #4caf50;
}

</style>
