<template>
    <div>
      <!-- Logo de la página -->
      <header class="header">
        <img src="https://www.fortunia.org/img/logo.0484ebc9.png" alt="Logo de Fortunia" class="logo" />
        <!-- Enlace para volver atrás -->
        <router-link to="/" class="back-link">← Atrás</router-link>
      </header>
  
      <!-- Título de la Política de Privacidad -->
      <h1 class="title">Política de Privacidad</h1>
  
      <!-- Contenido de la Política de Privacidad -->
      <section class="content">
        <p>En este sitio web nos comprometemos a proteger tu privacidad. Cualquier información personal que nos proporciones será utilizada exclusivamente para el propósito que se indique al momento de su recolección.</p>
  
        <p>No compartiremos tu información con terceros sin tu consentimiento, excepto cuando sea necesario para cumplir con una obligación legal o contractual.</p>
  
        <p>Si tienes alguna pregunta o inquietud sobre nuestra Política de Privacidad, no dudes en contactarnos a través del formulario de contacto en nuestro sitio web.</p>
  
        <h2>Datos que recopilamos</h2>
        <p>Podemos recopilar información personal como tu nombre, dirección de correo electrónico y detalles relacionados con el uso de nuestros servicios. Estos datos se recopilan únicamente con tu consentimiento explícito.</p>
  
        <h2>Cómo usamos tu información</h2>
        <p>La información que recopilamos se utiliza para:</p>
        <ul>
          <li>Proporcionar y mejorar nuestros servicios.</li>
          <li>Procesar solicitudes y transacciones.</li>
          <li>Comunicar actualizaciones importantes sobre tu cuenta o servicios.</li>
        </ul>
  
        <h2>Seguridad de la información</h2>
        <p>Implementamos medidas de seguridad técnicas y organizativas para proteger tu información personal contra accesos no autorizados, alteraciones o destrucción.</p>
  
        <p>Recuerda que, aunque hacemos todo lo posible para proteger tu privacidad, ninguna transmisión de datos por Internet es completamente segura.</p>
  
        <p>Última actualización: 16 de diciembre de 2024.</p>
      </section>
  
     
       <!-- Pie de página -->
     <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>
      
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy",
  };
  </script>
  
  <style scoped>
  /* Estilos generales */
  body {
    font-family: 'Helvetica Neue', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  }
  
  /* Header */
  .header {
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .logo {
    max-width: 100%;
    height: auto;
    max-width: 180px;
  }
  
  /* Enlace para volver atrás */
 /* Botón Atrás */
.back-button {
  text-align: left;
  margin: 20px;
}

.back-link {
  color: #007bff;
  font-size: 1rem;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #e0f7fa;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #b2ebf2;
}
  /* Título */
  .title {
    color: #333;
    font-size: 2rem;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Sección de Política de Privacidad */
  .content {
    margin: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
  }
  
  h1, h2 {
    color: #333;
    font-weight: 600;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  /* Lista */
  ul {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  /* Footer */
  .footer {
    margin-top: 40px;
    padding: 20px;
    background-color: #f4f4f4;
    text-align: center;
    border-top: 1px solid #ddd;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer nav ul li {
    display: inline;
  }
  
  .footer nav ul li a {
    text-decoration: none;
    color: #007bff;
    font-size: 1rem;
  }
  
  .footer nav ul li a:hover {
    text-decoration: underline;
  }
  
  /* Estilos para pantallas pequeñas */
  @media (max-width: 768px) {
    .footer nav ul {
      flex-direction: column;
      gap: 10px;
    }
  
    .logo {
      max-width: 150px;
    }
  
    .content {
      margin: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.2rem;
    }
  
    .footer p {
      font-size: 0.9rem;
    }
  }
  
  /* Estilos para pantallas muy pequeñas */
  @media (max-width: 480px) {
    h1 {
      font-size: 1.2rem;
    }
  
    h2 {
      font-size: 1rem;
    }
  
    .footer {
      padding: 15px;
    }
  
    .footer p {
      font-size: 0.8rem;
    }
  
    .footer nav ul {
      gap: 5px;
    }
  }
  </style>
  